




































































































































import { mainStore } from "@/store/main";
import { Vue, Component, Ref, Prop, Emit } from "vue-property-decorator";
import Section from "@/components/Section";
import { ContentsState, SimpleLayoutMode } from "@/constants";
import { ListItemData } from "@/@types/common";

@Component
export default class Footer extends Section {
  protected listData: ListItemData[] = [];

  protected get classObj() {
    return {
      "is-current": mainStore.contentsState === ContentsState.FOOTER,
      "is-noWebGL": this.isNoWebGL,
      "is-inited": this.isInited,
      "is-entered": this.isEntered,
      "is-leaved": this.isLeaved,
      "is-beforeEnter": this.isBeforeEnter,
      "is-beforeLeave": this.isBeforeLeave,
    };
  }

  @Emit("clickconcept")
  protected onClickConceptButton() {
    console.log("onClickConceptButton");
  }

  @Emit("clickfeatures")
  protected onClickFeatureButton() {
    console.log("onClickFeatureButton");
  }

  @Emit("clicklist")
  protected onClickListButton() {
    console.log("onClickListButton");
  }

  @Emit("clickscrolltop")
  protected onClickScrollTop() {
    console.log("onClickScrollTop");
  }

  @Emit("clicklogo")
  protected onClickLogo() {
    console.log("onClickLogo");
  }

  public setListData(listData: ListItemData[]) {
    this.listData = listData;
  }

  protected get isSimpleLayoutL() {
    return mainStore.simpleLayoutMode === SimpleLayoutMode.L;
  }

  protected get iconBlankRef() {
    return this.isSimpleLayoutL ? "#iconBlankS" : "#iconBlank";
  }

  protected get logoHRef() {
    return this.isSimpleLayoutL ? "#logoH2" : "#logoH";
  }
}


const html = document.querySelector("html") as HTMLElement;

// ------------------------------
// polyfill helper
// ------------------------------
import '@/components/polyfillHelper.js';

// ------------------------------
// inert pollyfill
// ------------------------------
import 'wicg-inert';

// ------------------------------
// focus visible polyfill
// ------------------------------
import 'focus-visible';
html.classList.add('js-focus-visible');


// ------------------------------
// Intersection Observer polyfill
// ------------------------------
// import 'intersection-observer';


// ------------------------------
// Audio Context
// ------------------------------
// import '../components/_utils/audioContext';


// ------------------------------
// detect passive event support
// ------------------------------
const passiveEventPromise = new Promise((resolve, reject)=> {
  try {
    window.addEventListener(
      "load",
      ()=> {},
      Object.defineProperty({}, "passive", {
        get: function () {
          resolve(true)
        },
      })
    );
  } catch (err) {
    reject(false)
  }
})

// ------------------------------
// detect webp support
// ------------------------------
import { checkIfWebpSupported } from '@/utils/img';
let isWebpSupported = false;
const webpLoadPromise = checkIfWebpSupported()
.then((_isWebpSupported)=> {
  if(_isWebpSupported) {
    isWebpSupported = true;
    console.log('WebP is supported');

  } else {
    html.classList.add('is-webpNotSupported');
    console.log('WebP is not supported');
  }
  return isWebpSupported;
})


// ------------------------------
// parseGetParams
// ------------------------------
import { parseGetParams } from '@/utils/string';
const params = parseGetParams();
console.log({ params });


// ------------------------------
// dat.gui
// ------------------------------
// console.log('dat gui debug on');
// const dat = require('dat.gui');
// const gui = new dat.GUI();
// gui.domElement.parentElement.style.zIndex = '10000';
// global.gui = gui;
// global.debugObj = {};


// ------------------------------
// checkDevice
// ------------------------------
import MobileDetect from 'mobile-detect';

let device: 'tablet' | 'mobile' | 'desktop' | '' = '';
const uaString = navigator && navigator.userAgent;
if (uaString) {
  const mobileDetect = new MobileDetect(uaString);

  if(mobileDetect.tablet()) {
    device = 'tablet';
    console.log('mobile detect: tablet');
    // const _WIDTH = 1200;
    // if(window.innerWidth < _WIDTH) {
    //   const initilScale = window.innerWidth / _WIDTH;
    //   document.querySelector('meta[name="viewport"]').setAttribute('content', `width=${_WIDTH},initial-scale=${initilScale}`);
    // }

  } else if(mobileDetect.mobile()) {
    device = 'mobile';
    console.log('mobile detect: mobile');

  } else {
    device = 'desktop';
    console.log('mobile detect: desktop');

  }
}

import {
  isiOS,
  isAndroid,
  isIE11,
  isEdge,
  isChrome,
  isFirefox,
  isWindows,
  isMacintosh,
  isGoogleBot
} from '@/utils/device';

const uaChecks = {
  isiOS: isiOS(),
  isAndroid: isAndroid(),
  isIE11: isIE11(),
  isEdge: isEdge(),
  isChrome: isChrome(),
  isFirefox: isFirefox(),
  isWindows: isWindows(),
  isMacintosh: isMacintosh(),
  isGoogleBot: isGoogleBot(),
}
if (uaChecks.isiOS) html.classList.add("is-ios");
if (uaChecks.isAndroid) html.classList.add("is-android");
if (uaChecks.isIE11) html.classList.add("is-ie11");
if (uaChecks.isEdge) html.classList.add("is-edge");
if (uaChecks.isFirefox) html.classList.add("is-firefox");
if (uaChecks.isWindows && uaChecks.isChrome) html.classList.add("is-winchrome");
if (uaChecks.isWindows) html.classList.add("is-windows");
if (uaChecks.isMacintosh) html.classList.add("is-macintosh");
if (uaChecks.isGoogleBot) html.classList.add("is-googlebot");

// ------------------------------
// detect has hover
// ------------------------------
let hasHover = false;
if('ontouchend' in document) {
  // タッチデバイスではtrue (ただし、Surfaceのようなタッチスクリーンのラップトップではfalseになる)
  hasHover = false;
  console.log('doesn\'t have hover');
} else {
  // ラップトップ、デスクトップPC？
  hasHover = true;
  html.classList.add("has-hover");
  console.log('has hover');
}


// -----------------------------
// webfont loader
// -----------------------------
import WebFont from "webfontloader";
const webFontLoaderPromise: Promise<string> = new Promise((resolve) => {
  console.log("loading fonts...");
  WebFont.load({
    google: {
      families: [
        "Inter:500,700;&display=swap",
      ]
    },
    // custom: {
    //   families: [ 'YakuHanJP_Noto' ],
    //   // urls: ['/assets/css/style.css'],
    //   testStrings: {
    //     'YakuHanJP_Noto': '、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝'
    //   }
    // },

    timeout: 10000,

    fontactive: (familyName) => {
      console.log("fontactive", familyName);
    },

    fontinactive: (familyName) => {
      console.error("fontinactive", familyName);
    },

    active: () => {
      resolve('');
      console.log("all fonts loaded");
    },

    inactive: () => {
      console.error("font inactive");
      resolve("load fonts error");
    }
  });
});

const initPromise = Promise.all([
  webFontLoaderPromise,
  webpLoadPromise
])

export default ()=> {
  return {
    initPromise,
    webFontLoaderPromise,
    webpLoadPromise,
    device,
    uaChecks,
    hasHover,
    passiveEventPromise
  }
}

























































































import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';

import Swiper, { SwiperOptions } from 'swiper';
import 'swiper/swiper-bundle.css';

import { ContentsState, SimpleLayoutMode, SimpleLayoutModeProp } from '@/constants';
import { ListItemData } from '@/@types/common';

import Section from '@/components/Section';
import ScrollAttention from '@/components/ScrollAttention.vue';

const CONTAINER_PADDING_BOTTOM_L = 40;
const CONTAINER_PADDING_TOP_L = 250;

@Component({
  components: {
    ScrollAttention
  }
})
export default class List extends Section {
  protected listData: ListItemData[] = [];
  protected swiper! : Swiper;
  protected simpleLayoutMode: SimpleLayoutModeProp | '' = '';
  protected slideIndex = 0;
  protected indicatorPosTop = 0;
  protected imgExt = '.jpg';
  protected slideIndexStr = '1';
  protected numSlides = 0;
  protected numSlidesStr = '--';
  protected numDuplicated = 0;
  protected isBeginning = true;
  protected isEnd = false;

  @Ref('swiperContainer') protected swiperContainer!: HTMLElement;

  @Emit('clickscroll')
  protected onClickScroll() {
    return this;
  }

  public setListData(listData: ListItemData[]) {
    this.listData = listData;
    this.numSlides = this.listData.length
    this.numSlidesStr = this.numSlides.toString();
  }

  protected get classObj() {
    return {
      'is-current': mainStore.contentsState === ContentsState.LIST,
      'is-noWebGL': this.isNoWebGL,
      'is-inited': this.isInited,
      'is-entered': this.isEntered,
      'is-leaved': this.isLeaved,
      'is-beforeEnter': this.isBeforeEnter,
      'is-beforeLeave': this.isBeforeLeave,
    }
  }

  protected get indicatorStyleObj() {
    if(this.isSimpleLayoutL) {
      return null;
    } else {
      return {
        top: `${this.indicatorPosTop}px`
      }
    }
  }

  protected get isSimpleLayoutL() {
    return mainStore.simpleLayoutMode === SimpleLayoutMode.L;
  }

  protected async initSwiper() {
    if(this.simpleLayoutMode === mainStore.simpleLayoutMode) return;

    // const listDataOrg = this.listData.slice()
    // while(this.listData.length < 6) {
    //   this.listData = [
    //     ...this.listData,
    //     ...listDataOrg,
    //   ]
    //   this.numDuplicated++
    // }

    await this.$nextTick()

    this.simpleLayoutMode = mainStore.simpleLayoutMode;
    // const options: SwiperOptions = {
    //   speed: 600,
    //   loop: true,
    //   loopedSlides: 1,
    //   touchMoveStopPropagation: true
    // };
    // if(this.simpleLayoutMode === SimpleLayoutMode.S) {
    //   options.slidesPerView = 'auto';
    //   options.centeredSlides = true;
    //   options.loopAdditionalSlides = 2;
    // } else {
    //   options.slidesPerView = this.$head.uaChecks.isIE11? 3: 'auto';
    //   options.centeredSlides = false;
    //   options.loopAdditionalSlides = 6;
    // }
    const options: SwiperOptions = {
      speed: 600,
      loop: false,
      touchMoveStopPropagation: true,
      slidesPerView: 'auto'
    };
    if(this.simpleLayoutMode === SimpleLayoutMode.S) {
      options.centeredSlides = true;
    } else {
      options.centeredSlides = false;
    }

    if(this.swiper) {
      this.swiper.off('slideChange');
      this.swiper.destroy(true, true);
    }
    this.swiper = new Swiper(this.swiperContainer, options)
    // this.swiper.on('slideChange', ()=> {
    //   this.slideIndex = this.swiper.realIndex;
    //   console.log(this.swiper.allowSlideNext, this.swiper.allowSlidePrev, this.swiper.isBeginning, this.swiper.isEnd);
    //   this.slideIndexStr = (this.slideIndex % this.numSlides + 1).toString();
    // })
    this.swiper.on('progress', ()=> {
      this.isBeginning = this.swiper.isBeginning;
      this.isEnd = this.swiper.isEnd;
    })
    await this.$nextTick();

    this.swiper.update()
  }

  public updateListGridParams() {
    const rect = (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).getBoundingClientRect();
    this.glCommonMesh.setListGridParams(rect.width, rect.x - rect.width);
  }

  protected onBeforeEnter() {
    this.updateListGridParams();
  }

  public async init() {
    this.imgExt = (await this.$head.webpLoadPromise)? '.webp': '.jpg';
    await this.$nextTick();
    this.initSwiper();
  }

  public async onResize() {
    this.initSwiper();

    await this.$nextTick();
    const slide = this.swiper.slides[this.swiper.activeIndex] as HTMLElement;
    const slideImg = slide.querySelector('img')?.parentElement as HTMLElement;
    const slideHeader = slide.querySelector('.js-slideItemHeader') as HTMLElement;

    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd

    if(mainStore.simpleLayoutMode === SimpleLayoutMode.L && window.innerHeight > 900) {
      let slideItemWidth = window.innerHeight - CONTAINER_PADDING_TOP_L - CONTAINER_PADDING_BOTTOM_L - slideHeader.offsetHeight;
      slideItemWidth = Math.min(950, slideItemWidth);
      this.setSlidesWidth(slideItemWidth);

    } else {
      this.setSlidesWidth(-1);
    }

    this.indicatorPosTop = slideImg.offsetTop + slideImg.offsetHeight + 10;
    
    this.updateListGridParams();
  }

  protected setSlidesWidth(slideWidth: number) {
    const width = slideWidth === -1? '': `${slideWidth}px`;
    for (let i = 0, l = this.swiper.slides.length; i < l; i++) {
      const slide = this.swiper.slides[i];
      (slide as HTMLElement).style.width = width;
    }
  }

  public onClickSlideNavPrev() {
    this.swiper?.slidePrev();
  }
  
  public onClickSlideNavNext() {
    this.swiper?.slideNext();
  }

  public reset() {
    this.swiper?.slideReset();
    this.slideIndex = 0;
    this.slideIndexStr = '1';
  }
}

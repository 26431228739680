







































import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';
import { SimpleLayoutMode } from '@/constants';
import ScrollContents from './ScrollContents';
import { FeaturesData } from '@/@types/common';

@Component
export default class FeatureContents extends ScrollContents {
  protected isMainImgLoaded = false;
  protected imgSrc = '';
  protected title = '';
  protected body = '';

  @Ref() protected mainImg!: HTMLImageElement;

  protected get classObj() {
    return {
      'is-atEnd': !this.isScrollAvailable || this.isAtEnd,
      'is-atBegin': this.isAtBegin,
      'is-noScrollbar': !this.isScrollAvailable,
      'is-opened': this.isOpened,
    }
  }
  
  protected get isOpened() {
    return mainStore.isFeatureContentsOpened;
  }

  public async setContents(contentsData: FeaturesData) {
    this.isMainImgLoaded = false;
    this.reset();

    await this.$nextTick();

    this.imgSrc = contentsData.acf.image.url;
    this.title = contentsData.title.rendered;
    this.body = contentsData.content.rendered;

    await this.$nextTick();
    this.onResize();
  }

  protected onLoad() {
    this.isMainImgLoaded = true;
  }

  public reset() {
    this.imgSrc = '';
    this.title = '';
    this.body = '';

    this.isAtBegin = true;
    this.isAtEnd = false;
    this.scrollPos = 0;
    this.scrollbarKnobPos = 0;
    this.isSwipingOverToBegin = false;
    this.isSwipingOverToEnd = false;
    this.isAtBeginBackup = false;
    this.isAtEndBackup = false;
    this.updateScrollbarPos();
  }

  protected onTransitionEnd() {
    if(this.isOpened) {
      this.enable();
    } else {
      this.reset();
      this.disable();
    }
  }
}

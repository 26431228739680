

























































































































import { mainStore } from "@/store/main";
import { Vue, Component, Ref, Emit, Prop } from "vue-property-decorator";
import Section from "@/components/Section";
import ScrollAttention from "@/components/ScrollAttention.vue";
import {
  ContentsState,
  ContentsStateProp,
  LayoutMode,
  SimpleLayoutMode,
  SimpleLayoutModeProp,
  SP_DESIGN_WIDTH_BASIS,
} from "@/constants";
import { TextureData, KVPopUpData } from "@/@types/common";

import gsap, { Expo, Cubic } from "gsap/src/gsap-core";

const LOGO_ASPECT_RATIO = (240 / 217) * 0.5;

type ImgCopyPosDataPosData = {
  prop: "left" | "right" | "top" | "bottom";
  value: number | string;
  type: "percent" | "normal";
};

type ImgCopyPosData = {
  posX: ImgCopyPosDataPosData;
  posY: ImgCopyPosDataPosData;
};

type ImgCopyData = {
  txt: string;
  S: ImgCopyPosData;
  L: ImgCopyPosData;
};

const IMG_COPIES_DATA: ImgCopyData[] = [
  {
    txt: "働き方を変えるオフィスから、<br>暮らし方を変えるオフィスへ",
    S: {
      posX: {
        prop: "left",
        value: 40,
        type: "normal",
      },
      posY: {
        prop: "top",
        value: `${(310 / 1400) * 100}%`,
        type: "percent",
      },
    },
    L: {
      posX: {
        prop: "right",
        value: `${(416 / 1600) * 100}%`,
        type: "percent",
      },
      posY: {
        prop: "top",
        value: `${(294 / 900) * 100}%`,
        type: "percent",
      },
    },
  },
  {
    txt: "たのしむ人は、<br class='is-s'>いい仕事をする。",
    S: {
      posX: {
        prop: "left",
        value: 40,
        type: "normal",
      },
      posY: {
        prop: "top",
        value: `${(310 / 1400) * 100}%`,
        type: "percent",
      },
    },
    L: {
      posX: {
        prop: "right",
        value: `${(280 / 1600) * 100}%`,
        type: "percent",
      },
      posY: {
        prop: "top",
        value: `${(276 / 900) * 100}%`,
        type: "percent",
      },
    },
  },
  {
    txt: "職場に家庭を<br class='is-s'>持ち込もう",
    S: {
      posX: {
        prop: "left",
        value: 40,
        type: "normal",
      },
      posY: {
        prop: "top",
        value: `${(310 / 1400) * 100}%`,
        type: "percent",
      },
    },
    L: {
      posX: {
        prop: "right",
        value: `${(428 / 1600) * 100}%`,
        type: "percent",
      },
      posY: {
        prop: "top",
        value: `${(326 / 900) * 100}%`,
        type: "percent",
      },
    },
  },

  {
    txt: "働くだけなんて、<br>もったいない。",
    S: {
      posX: {
        prop: "left",
        value: 40,
        type: "normal",
      },
      posY: {
        prop: "top",
        value: `${(310 / 1400) * 100}%`,
        type: "percent",
      },
    },
    L: {
      posX: {
        prop: "right",
        value: `${(460 / 1600) * 100}%`,
        type: "percent",
      },
      posY: {
        prop: "top",
        value: `${(377 / 900) * 100}%`,
        type: "percent",
      },
    },
  },
];

@Component({
  components: {
    ScrollAttention,
  },
})
export default class KV extends Section {
  protected isOpening = false;
  protected isPopUpVisible = false;
  protected isTimerPlaying = false;
  protected texturesData: TextureData[] = [];
  protected imgIndex = 0;
  protected numImgs = 0;
  protected imgSwapTimer = -1;
  protected waitTimer = -1;
  protected indicatorCircleDashArray = 0;
  protected popUpTitleAnimationDuration = 0;
  protected popUpTitleAnimationDelay = 0;
  protected isPopUpTitleRunning = false;
  protected imgCopiesData: ImgCopyData[] = IMG_COPIES_DATA;
  protected popUpTitleAnimationTimer = -1;

  protected phase = 0;
  protected phase0LogoScale = 0;
  protected phase0LogoRect!: DOMRect;
  protected openingTimeline!: gsap.core.Timeline;

  @Prop({ type: Object })
  protected popUpData!: KVPopUpData;

  @Ref() protected globalLoadingIndicatorDummy!: HTMLElement;
  @Ref() protected logoLeft!: HTMLElement;
  @Ref() protected logoRight!: HTMLElement;
  @Ref() protected navIndicator!: HTMLElement;
  @Ref() protected popUpInner!: HTMLElement;
  @Ref() protected popUpTitle!: HTMLElement;

  protected get classObj() {
    return {
      "is-current": mainStore.contentsState === ContentsState.KV,
      "is-noWebGL": this.isNoWebGL,
      "is-inited": this.isInited,
      "is-popUpVisible": this.isPopUpVisible,
      "is-timerPlaying": this.isTimerPlaying,
      [`is-phase${this.phase}`]: !this.isNoWebGL && this.isInited,
      "is-entered": this.isEntered,
      "is-leaved": this.isLeaved,
      "is-beforeEnter": this.isBeforeEnter,
      "is-beforeLeave": this.isBeforeLeave,
      "is-opening": this.isOpening,
    };
  }

  protected get popUpTitleStyleObj() {
    if (mainStore.simpleLayoutMode === SimpleLayoutMode.L) return null;
    return {
      "animation-duration": `${this.popUpTitleAnimationDuration}s`,
      "animation-delay": `${this.popUpTitleAnimationDelay}s`,
    };
  }

  protected getImgCopyClassObj(imgCopyData: ImgCopyData, index: number) {
    return {
      "is-current": this.imgIndex === index,
      [`is-${imgCopyData[mainStore.simpleLayoutMode].posX.prop}`]: true,
    };
  }

  protected getImgCopyStyleObj(imgCopyData: ImgCopyData) {
    const simpleLayoutMode = mainStore.simpleLayoutMode;
    const posData = imgCopyData[simpleLayoutMode];

    return {
      ...this.getImgCopyPosStyleObj(posData.posX, simpleLayoutMode),
      ...this.getImgCopyPosStyleObj(posData.posY, simpleLayoutMode),
    };
  }

  protected getImgCopyPosStyleObj(
    posData: ImgCopyPosDataPosData,
    simpleLayoutMode: SimpleLayoutModeProp
  ) {
    let styleObj: { [key: string]: string } = {};
    if (posData.type === "percent") {
      styleObj[posData.prop] = posData.value as string;
    } else {
      if (simpleLayoutMode === SimpleLayoutMode.S) {
        if (mainStore.layoutMode === LayoutMode.SS) {
          styleObj[posData.prop] = `${((posData.value as number) /
            SP_DESIGN_WIDTH_BASIS) *
            100}vw`;
        } else {
          styleObj[posData.prop] = `${((posData.value as number) /
            SP_DESIGN_WIDTH_BASIS) *
            414}px`;
        }
      } else {
        styleObj[posData.prop] = `${posData.value}px`;
      }
    }

    return styleObj;
  }

  protected get logoLeftStyleObj() {
    const obj: { width: string; left?: string; transform?: string } = {
      width: `${this.logoWidth}px`,
    };
    if (this.isNoWebGL) return obj;
    if (this.phase === 0) {
      obj.transform = `translateX(-100%) translateX(${this.width * 0.5 +
        1}px) scale(${this.phase0LogoScale})`;
    } else if (this.phase === 1) {
      obj.transform = `translateX(-100%) translateX(${this.width * 0.5 + 1}px)`;
    }
    return obj;
  }

  protected get logoRightStyleObj() {
    const obj: { width: string; left?: string; transform?: string } = {
      width: `${this.logoWidth}px`,
    };
    if (this.isNoWebGL) return obj;
    if (this.phase === 0) {
      obj.transform = `translateX(${-this.width * 0.5 - 1}px) scale(${
        this.phase0LogoScale
      })`;
    } else if (this.phase === 1) {
      obj.transform = `translateX(${-this.width * 0.5 - 1}px)`;
    }
    return obj;
  }

  protected get indicatorCircleStyleObj() {
    return {
      "stroke-dasharray": `${this.indicatorCircleDashArray}px`,
      "stroke-dashoffset": `${this.indicatorCircleDashArray}px`,
    };
  }

  protected get isSimpleLayoutL() {
    return mainStore.simpleLayoutMode === SimpleLayoutMode.L;
  }

  protected get popUpTitleStr() {
    return this.popUpData?.title.rendered || "";
  }

  protected get popUpLink() {
    return this.popUpData?.acf.link || "";
  }

  protected get popUpImg() {
    return this.popUpData?.acf.image.url || "";
  }

  protected get popUpDateTime() {
    return this.popUpData?.date ? this.popUpData?.date + "+09:00" : "";
  }

  protected get popUpDateTimeStr() {
    return this.popUpDateTime.replace(/T.*$/, "");
  }

  protected get imgs() {
    return this.texturesData.map((textureData: TextureData) => {
      return textureData[mainStore.simpleLayoutMode];
    });
  }

  @Emit("clickscroll")
  protected onClickScroll() {
    return this;
  }

  protected onClickClosePopUp() {
    this.isPopUpVisible = false;
  }

  public setTextures(texturesData: TextureData[]) {
    this.texturesData = texturesData;
  }

  public async init() {
    this.isInited = true;
    this.numImgs = this.texturesData.length;
  }

  protected showUI() {
    mainStore.setIsUIAvailable(true);
  }

  protected onOpeningFinish() {
    mainStore.setIsOpeningFinished(true);
  }

  public async playOpening() {
    if (this.isNoWebGL) {
      // no webgl
      window.setTimeout(() => {
        this.setImgSwapTimer();
        this.isPopUpVisible = true;
      }, 2000);
    } else {
      this.isOpening = true;
      this.isEntered = true;
      if (this.width / this.height <= 1) {
        // 縦長
        this.openingTimeline = gsap
          .timeline({
            onUpdate: () => {
              this.updateKVMeshLogo();
            },
          })
          .add(() => {
            this.phase = 2;
          }, 2)
          .add(this.glKVMesh.fadeIn(), 2)
          .add(this.glKVMesh.animateScale(), 1.6)
          .add(() => this.showUI(), 2.6)
          .add(() => {
            this.isPopUpVisible = true;
            this.setImgSwapTimer();
            this.glCommonMesh.enable();
          }, 3.6)
          .add(() => {
            this.isOpening = false;
            this.isPopUpTitleRunning = true;
            this.onOpeningFinish();
          }, 3.6 + 1.2);
      } else {
        // 横長
        this.openingTimeline = gsap
          .timeline({
            onUpdate: () => {
              this.updateKVMeshLogo();
            },
          })
          .add(() => {
            this.phase = 1;
          }, 2)
          .add(this.glKVMesh.fadeIn(), 2)
          .add(() => {
            this.phase = 2;
          }, 2.4)
          .add(this.glKVMesh.animateScale(), 2)
          .add(() => this.showUI(), 3)
          .add(() => {
            this.isPopUpVisible = true;
            this.setImgSwapTimer();
            this.glCommonMesh.enable();
          }, 4)
          .add(() => {
            this.isOpening = false;
            this.isPopUpTitleRunning = true;
            this.onOpeningFinish();
          }, 4 + 1.2);
      }
    }
  }

  public async leave(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: () => void = () => {}
  ) {
    this.clearPopUpTitleAnimationTimer();
    this.isLeaved = true;
    this.isEntered = false;
    this.glKVMesh.leave(() => {
      this.isPopUpTitleRunning = false;
      onComplete();
    });
    this.isPopUpVisible = false;
    this.openingTimeline?.kill();
    this.clearImgSwapTimer();
  }

  public async enter(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: () => void = () => {}
  ) {
    this.clearPopUpTitleAnimationTimer();
    this.isPopUpTitleRunning = false;
    this.isLeaved = false;
    this.isEntered = true;
    this.isPopUpVisible = true;
    this.glKVMesh.enter(() => {
      this.setImgSwapTimer();
      this.setPopUpTitleAnimationTimer();
      onComplete();
    });
  }

  protected async setImgSwapTimer() {
    this.isTimerPlaying = false;

    await this.$nextTick();

    if (this.waitTimer) window.clearTimeout(this.waitTimer);
    this.waitTimer = window.setTimeout(() => {
      this.isTimerPlaying = true;
      this.clearImgSwapTimer();
      this.imgSwapTimer = window.setTimeout(() => {
        this.onClickNavNext();
      }, 6000);
    }, 100);
  }

  protected clearImgSwapTimer() {
    if (this.imgSwapTimer) window.clearTimeout(this.imgSwapTimer);
  }

  protected onClickNavPrev() {
    const prevIndex = (this.imgIndex - 1 + this.numImgs) % this.numImgs;
    if (this.isNoWebGL) {
      this.imgIndex = prevIndex;
      this.setImgSwapTimer();
    } else if (!this.glKVMesh.getIsAnimating()) {
      this.glKVMesh.animateToNextImg(prevIndex);
      this.imgIndex = prevIndex;
      this.setImgSwapTimer();
    }
  }

  protected onClickNavNext() {
    const nextIndex = (this.imgIndex + 1) % this.numImgs;
    if (this.isNoWebGL) {
      this.imgIndex = nextIndex;
      this.setImgSwapTimer();
    } else if (!this.glKVMesh.getIsAnimating()) {
      this.glKVMesh.animateToNextImg(nextIndex);
      this.imgIndex = nextIndex;
      this.setImgSwapTimer();
    }
  }

  public onResize() {
    const el = this.$el as HTMLElement;
    if (!el) return;

    this.width = el.offsetWidth;
    this.height = el.offsetHeight;

    this.logoWidth = this.height * LOGO_ASPECT_RATIO;

    if (this.isNoWebGL) return;

    this.phase0LogoRect = this.globalLoadingIndicatorDummy.getBoundingClientRect();
    this.phase0LogoScale = this.phase0LogoRect.height / this.height;

    this.indicatorCircleDashArray =
      (this.navIndicator.offsetWidth - 2) * Math.PI;

    this.updatePopUpTitleAnimation();
    this.updateKVMeshLogo();
  }

  protected updatePopUpTitleAnimation() {
    if (mainStore.simpleLayoutMode === SimpleLayoutMode.L) return;

    const popUpTitleW = this.popUpTitle.offsetWidth;
    const scrollWidth = this.popUpInner.offsetWidth + popUpTitleW;
    const delayW = this.popUpInner.offsetWidth;

    this.popUpTitleAnimationDuration = (popUpTitleW / 375) * 10;
    this.popUpTitleAnimationDelay =
      (-this.popUpTitleAnimationDuration * delayW) / scrollWidth;
  }

  protected updateKVMeshLogo() {
    const leftRect = this.logoLeft.getBoundingClientRect();
    const rightRect = this.logoRight.getBoundingClientRect();
    this.glKVMesh.setLogoEdges(
      leftRect.y,
      rightRect.x + rightRect.width,
      leftRect.y + leftRect.height,
      leftRect.x
    );
  }

  protected clearPopUpTitleAnimationTimer() {
    if (this.popUpTitleAnimationTimer)
      window.clearTimeout(this.popUpTitleAnimationTimer);
  }

  protected setPopUpTitleAnimationTimer() {
    this.clearPopUpTitleAnimationTimer();
    this.popUpTitleAnimationTimer = window.setTimeout(() => {
      this.isPopUpTitleRunning = true;
    }, 800);
  }
}

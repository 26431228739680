




































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import gsap, { Expo } from 'gsap/src/gsap-core';

import { mainStore } from '@/store/main';

@Component
export default class GlobalLoading extends Vue {
  protected percent = 0;
  protected indicatorInnerWidth = 0;
  protected isPhase1 = false;

  @Prop({ type: Number, default: 0, required: true })
  protected loadedCount!: number;
  
  @Prop({ type: Number, default: 0, required: true })
  protected totalLoadCount!: number;

  @Watch('loadedCount')
  protected onChangeLoadedCount() {
    this.indicatorInnerWidth = window.innerWidth * 0.7 * (1 - this.loaded);
  }

  protected get loaded() {
    if(this.totalLoadCount === 0) return 0;
    let loaded = this.loadedCount === this.totalLoadCount? 1: this.loadedCount / this.totalLoadCount;
    if(loaded >= 1 && !this.isPhase1) {
      window.setTimeout(()=> {
        this.isPhase1 = true;
      }, 200)
    }
    gsap.to(this, { percent: Math.round(loaded * 100), duration: 0.4, ease: Expo.easeOut, onComplete: ()=> {
      if(loaded >= 1) {
        window.setTimeout(()=> {
          mainStore.setIsLoaded(true);
        }, 400)
      }
    }})
    return loaded;
  }

  protected get percentStr() {
    const p = Math.round(this.percent);
    return p >= 100? '100': ('00' + p.toString()).slice(-2);
  }

  protected get indicatorInnerStyle() {
    return {
      width: `${this.indicatorInnerWidth}px`,
      transform: `translateX(-50%) scaleX(${(1 - this.percent / 100)})`
    }
  }

  protected get indicatorLeftSTyle() {
    return {
      transform: `translateX(${-this.indicatorInnerWidth * 0.5 * (1 - this.percent / 100)}px)`
    }
  }
  
  protected get indicatorRightSTyle() {
    return {
      transform: `translateX(${this.indicatorInnerWidth * 0.5 * (1 - this.percent / 100)}px)`
    }
  }

  protected get classObj() {
    return {
      'is-next': this.isPhase1,
      'is-noWebGL': mainStore.isNoWebGL
    }
  }
  
  protected get isLoaded() {
    return mainStore.isRootLoaded;
  }

  mounted() {
    window.scrollTo(0, 0);
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  
  protected handleResize() {
    this.indicatorInnerWidth = window.innerWidth * 0.7;
  }

  protected onBeforeLeave() {
    mainStore.enter();
  }

  protected onAfterLeave() {
    mainStore.entered();
  }
}



































import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';

@Component
export default class ScrollAttention extends Vue {
  protected get classObj() {
    return {
      'is-white': this.isWhite,
      'is-kv': this.isKv
    }
  }

  protected get svgGradientRef() {
    return `url(#${this.isKv? 'arrowGradient--kv': this.isWhite? 'arrowGradient--white': 'arrowGradient'})`;
  }

  @Prop({ type: Boolean, default: false })
  protected isWhite!: boolean;
  
  @Prop({ type: Boolean, default: false })
  protected isKv!: boolean;

  protected get label() {
    return this.$head.hasHover? 'Scroll': 'Swipe'
  }

  protected get isNoWebGL() {
    return mainStore.isNoWebGL;
  }
  
  @Emit('click')
  protected onClick() {
    return this
  }
}



const uaString = ((navigator && navigator.userAgent) || '').toLocaleLowerCase();
const appVersionString = (navigator && navigator.appVersion) || '';

export function isiPhone() {
  return uaString.toLowerCase().indexOf('iphone') !== -1;
}

export function isiPad() {
  return (uaString.indexOf('ipad') !== -1) || (uaString.indexOf('macintosh') !== -1 && 'ontouchend' in document);
}

export function isiPod() {
  return uaString.toLowerCase().indexOf('ipod') !== -1;
}

export function isiOS() {
  return isiPad() || isiPhone() || isiPod();
}

export function isAndroid() {
  return !!uaString.toLowerCase().match(/android/);
}

export function isChrome() {
  return !isEdge() && !!uaString.toLowerCase().match(/chrome/);
}

export function isFirefox() {
  return !!uaString.toLowerCase().match(/firefox/);
}

export function isEdge() {
  return !!uaString.toLowerCase().match(/edge?/);
}

export function isIE10() {
  return uaString.toLowerCase().indexOf('msie 10.0') !== -1;
}

export function isIE11() {
  return uaString.toLowerCase().indexOf('rv:11.0') !== -1;
}

export function isGoogleBot() {
  return !!uaString.toLowerCase().match(/googlebot/);
}

export function isWindows() {
  return uaString.toLowerCase().indexOf('windows') !== -1;
}

export function isMacintosh() {
  return uaString.toLowerCase().indexOf('macintosh') !== -1 && !isiPad();
}


export function getAndroidVersion() {
  if(appVersionString === '') return [];
  let v = appVersionString.match(/Android (\d+).(\d+).?(\d+)?;/);
  return v && [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
}

export function getiOSVersion() {
  if(appVersionString === '') return [];
  let v = appVersionString.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
}



import { OrthographicCamera  } from 'three/src/cameras/OrthographicCamera' ;
import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry'   ;
import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer'   ;
import { WebGL1Renderer } from 'three/src/renderers/WebGL1Renderer'   ;

import WebGLBase from './WebGLBase';
import KVMesh from './KVMesh';
import BgCommonMesh from './CommonMesh';

import { TextureData } from '@/@types/common';

import { GUI } from 'dat.gui';
import { mainStore } from '@/store/main';
import { ContentsState } from '@/constants';

export default class BgGL extends WebGLBase {
  protected kvMesh!: KVMesh;
  protected commonMesh!: BgCommonMesh;
  protected maxTextureSize = 2048;

  constructor(container: HTMLElement, canvas: HTMLCanvasElement | null = null) {
    super(container, canvas);
  }

  protected initRenderer() {
    super.initRenderer();
    this.setPixelRatio(window.devicePixelRatio);
  }

  public async initContents(): Promise<any> {
    const commonPlaneGeometry = new PlaneBufferGeometry(100, 100, 1, 1);
    this.kvMesh = new KVMesh(commonPlaneGeometry);
    this.commonMesh = new BgCommonMesh(commonPlaneGeometry);
    this.kvMesh.renderOrder = 1;
    this.commonMesh.renderOrder = 0;
    this.scene?.add(this.commonMesh);
    this.scene?.add(this.kvMesh);

    this.maxTextureSize = (this.renderer as WebGLRenderer).capabilities.maxTextureSize;
  }

  public async initKVTextuers(texturesData: TextureData[], loadItemCallback: () => void = ()=> {}) {
    this.kvMesh.onResize(this.width, this.height, this.pixelRatio);
    this.kvMesh.setTexturesData(texturesData);
    return this.kvMesh.loadTextures(loadItemCallback, this.maxTextureSize);
  }
  
  public async initCommonTextures(texturesData: TextureData[], loadItemCallback: () => void = ()=> {}) {
    this.commonMesh.onResize(this.width, this.height, this.pixelRatio);
    this.commonMesh.setTexturesData(texturesData);
    return this.commonMesh.loadTextures(loadItemCallback, this.maxTextureSize);
  }

  protected initCamera() {
    this.camera = new OrthographicCamera(-this.width * 0.5, this.width * 0.5, this.height * 0.5, -this.height * 0.5, 0, 100);
    this.camera.position.z = 100;
    this.camera.lookAt(0, 0, 0);
    this.camera.matrixAutoUpdate = false;
  }

  protected beforeRenderContents() {}
  
  protected afterRenderContents() {
    this.setPixelRatio(window.devicePixelRatio);
  }

  protected onResizeContents() {
    this.kvMesh.onResize(this.width, this.height, this.pixelRatio);
    this.commonMesh.onResize(this.width, this.height, this.pixelRatio);
  }

  public getKVMesh() {
    return this.kvMesh;
  }
  
  public getCommonMesh() {
    return this.commonMesh;
  }

  public playVideo() {
    this.commonMesh.playVideo();
  }
  
  public pauseVideo() {
    this.commonMesh.pauseVideo();
  }

  public initDatGUI(datGUI: GUI) {
    // this.kvMesh.initDatGUI(datGUI);
    this.commonMesh.initDatGUI(datGUI);
  }

  protected updateCamera() {
    const camera = this.camera as OrthographicCamera;
    camera.left = -this.width * 0.5;
    camera.right = this.width * 0.5;
    camera.top = this.height * 0.5;
    camera.bottom = this.height * 0.5;
    camera.updateMatrix();
    camera.updateProjectionMatrix();
  }

  public getIsAnimating() {
    if(mainStore.contentsState === ContentsState.KV) {
      return this.kvMesh.getIsAnimating()
    } else {
      return this.commonMesh.getIsAnimating()
    }
  }
}

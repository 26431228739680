
import { Vector2      } from 'three/src/math/Vector2'          ;
import { Texture      } from 'three/src/textures/Texture'      ;
import { VideoTexture } from 'three/src/textures/VideoTexture' ;
import { ClampToEdgeWrapping, LinearFilter } from 'three/src/constants';

import WebGLBase from './WebGLBase';

import { SimpleLayoutMode } from '@/constants';
import { mainStore } from '@/store/main';
import { TextureData } from '@/@types/common';

export default class CustomTexture {
  protected type: 'image' | 'video' = 'image';
  protected textureData!: TextureData;
  protected isPlaying = false;
  protected glContainerWidth = 2;
  protected glContainerHeight = 2;
  protected pixelRatio = 1;
  protected maxTextureSize = 2048;
  protected uvOffset: Vector2 = new Vector2();
  protected uvSize: Vector2 = new Vector2();

  constructor(textureData: TextureData, width: number, height: number, pixelRatio: number, maxTextureSize = 2048) {
    this.glContainerWidth = width;
    this.glContainerHeight = height;
    this.pixelRatio = pixelRatio;
    this.maxTextureSize = maxTextureSize;
    this.textureData = textureData;

    if(this.textureData.S.type === 'video') {
      this.type = 'video';
    }
  }

  public async onResize(width: number, height: number, pixelRatio: number) {
    this.glContainerWidth = width;
    this.glContainerHeight = height;
    this.pixelRatio = pixelRatio;
    const td = this.textureData[mainStore.simpleLayoutMode];
    
    const uvParams = WebGLBase.getUV(this.glContainerWidth, this.glContainerHeight, td.width, td.height, td.posX, td.posY);
    this.uvSize = uvParams.uvSize;
    this.uvOffset = uvParams.uvOffset;
    
    return this.load();
  }

  public getUVSize() { return this.uvSize; }
  public getUVOffset() { return this.uvOffset; }
  
  public play() {
    if(this.type === 'image') return;
    this.isPlaying = true;
    const video = this.textureData[mainStore.simpleLayoutMode].video;
    if(video) {
      document.body.appendChild(video);
      video.play()
    }
    
  }

  public pause() {
    if(this.type === 'image') return;
    this.isPlaying = false;
    
    const videoS = this.textureData.S.video;
    if(videoS) {
      videoS.parentElement?.removeChild(videoS)
      videoS.pause()
    }
    
    const videoL = this.textureData.L.video;
    if(videoL) {
      videoL.parentElement?.removeChild(videoL)
      videoL.pause()
    }
  }

  protected setTextureOptions(texture: Texture) {
    texture.wrapS = ClampToEdgeWrapping;
    texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.generateMipmaps = false;
    texture.needsUpdate = true;
  }

  public async load() {
    const td = this.textureData[mainStore.simpleLayoutMode];

    if(td.loadPromise) return td.loadPromise;

    const anotherTD = this.textureData[mainStore.simpleLayoutMode === SimpleLayoutMode.L? SimpleLayoutMode.S: SimpleLayoutMode.L];

    const loadPromise: Promise<Texture | void> = new Promise((resolve, reject)=> {
      if(this.type === 'image') {
        if(td.src) {
          td.img = document.createElement('img');
          td.img.decoding = 'async';
          const onComplete = ()=> {
            td.img?.removeEventListener('load', onComplete);
            const texture = new Texture(td.img);
            td.texture = texture;
            if(td.common) anotherTD.texture = texture;
            this.setTextureOptions(texture);
            resolve(texture);
          }
          td.img.addEventListener('load', onComplete, { once: true });
          td.img.src = `/img/${td.src}`;
          if(this.maxTextureSize >= 4096 && td.hasL) {
            td.img.src = td.img.src.replace(/\.(jpg|png|webp)/, "L.$1")
          }
        if(td.img.naturalWidth > 0) onComplete();
        } else {
          resolve()
        }

      } else {
        td.video = document.createElement('video');
        td.video.style.position = 'fixed';
        td.video.style.zIndex = '-100';
        td.video.style.top = '-2px';
        td.video.style.left = '-2px';
        td.video.style.width = '2px';
        td.video.style.height = '2px';
        td.video.playsInline = true;
        td.video.muted = true;
        td.video.loop = true;
        td.video.pause()
        document.body.appendChild(td.video);
        td.video.addEventListener('canplay', ()=> {
          const texture = new VideoTexture(td.video as HTMLVideoElement);
          td.texture = texture;
          if(td.common) anotherTD.texture = texture;
          this.setTextureOptions(texture);
          resolve(texture);
        }, { once: true })
        td.video.src = `/img/${td.src}`;
        td.video.load();
      }
    });
    td.loadPromise = loadPromise;
    if(td.common) anotherTD.loadPromise = loadPromise;
    return loadPromise;
  }
}













































import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';
import { SimpleLayoutMode } from '@/constants';
import ScrollContents from './ScrollContents';
import { FeaturesData } from '@/@types/common';

import gsap, { Expo } from 'gsap/src/gsap-core';

type ImgData = {
    url: string;
    sizes: {
        medium: string;
    };
};

@Component
export default class FeaturesList extends ScrollContents {
  protected body = '';
  protected isColumn2 = false;

  @Prop({ type: Array, default: [] })
  protected listData!: FeaturesData[];
  
  @Prop({ type: Boolean, default: false })
  protected isFeatureContentsOpened!: boolean;

  @Ref() protected wrapper!: HTMLElement;

  protected get classObj() {
    return {
      'is-atEnd': !this.isScrollAvailable || this.isAtEnd,
      'is-atBegin': this.isAtBegin,
      'is-noScrollbar': !this.isScrollAvailable,
      'is-column2': this.isColumn2,
    }
  }


  protected getThumbSrc(imgData: ImgData) {
    if(mainStore.simpleLayoutMode === SimpleLayoutMode.S) {
      return imgData.sizes.medium;
    } else {
      return imgData.url;
    }
  }

  public async onResize() {
    if(mainStore.simpleLayoutMode === SimpleLayoutMode.L) {
      const aspectRatio = (this.wrapper.offsetWidth - 100) / this.wrapper.offsetHeight;
      this.isColumn2 = aspectRatio < 0.62;

    } else {
      this.isColumn2 = false;
    }
    await this.$nextTick();
    this.resize();
  }
  

  @Emit('clickitem')
  protected onClickItem(index: number) {
    return index;
  }
}






































































import { mainStore } from "@/store/main";
import { Vue, Component, Ref, Emit } from "vue-property-decorator";
import Section from "@/components/Section";
import ScrollAttention from "@/components/ScrollAttention.vue";
import { ContentsState } from "@/constants";

const LOGO_DASH_ARRAY_BASIS_HEIGHT = 1000;
const LOGO_LEFT_DASH_ARRAY_BASE = 1560;
const LOGO_RIGHT_DASH_ARRAY_BASE = 2100;

const LOGO_ASPECT_RATIO = (240 / 217) * 0.5;

@Component({
  components: {
    ScrollAttention,
  },
})
export default class Concept extends Section {
  protected logoLeftDashArray = 0;
  protected logoRightDashArray = 0;

  @Ref("video") protected video!: HTMLMediaElement;

  @Emit("clickscroll")
  protected onClickScroll() {
    return this;
  }

  protected get logoLeftSVGStyleObj() {
    return {
      "stroke-dasharray": `${this.logoLeftDashArray}px`,
      "stroke-dashoffset": `${this.logoLeftDashArray}px`,
    };
  }

  protected get logoRightSVGStyleObj() {
    return {
      "stroke-dasharray": `${this.logoRightDashArray}px`,
      "stroke-dashoffset": `${this.logoRightDashArray}px`,
    };
  }

  protected get classObj() {
    return {
      "is-current": mainStore.contentsState === ContentsState.CONCEPT,
      "is-noWebGL": this.isNoWebGL,
      "is-inited": this.isInited,
      "is-entered": this.isEntered,
      "is-leaved": this.isLeaved,
      "is-beforeEnter": this.isBeforeEnter,
      "is-beforeLeave": this.isBeforeLeave,
    };
  }

  public onResize() {
    const el = this.$el as HTMLElement;
    if (!el) return;

    this.width = el.offsetWidth;
    this.height = el.offsetHeight;

    const h = this.height + 2;
    this.logoWidth = h * LOGO_ASPECT_RATIO;

    this.logoLeftDashArray =
      (h / LOGO_DASH_ARRAY_BASIS_HEIGHT) * LOGO_LEFT_DASH_ARRAY_BASE;
    this.logoRightDashArray =
      (h / LOGO_DASH_ARRAY_BASIS_HEIGHT) * LOGO_RIGHT_DASH_ARRAY_BASE;
  }
}














































import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';
import Section from '@/components/Section';
import ScrollAttention from '@/components/ScrollAttention.vue';
import { ContentsState, SimpleLayoutMode } from '@/constants';
import { FeaturesData } from '@/@types/common';

import FeatureContents from '@/components/FeatureContents.vue';
import FeaturesList from '@/components/FeaturesList.vue';

@Component({
  components: {
    FeatureContents,
    FeaturesList,
    ScrollAttention
  }
})
export default class Features extends Section {
  @Prop({ type: Array, default: [] })
  protected listData!: FeaturesData[];

  @Ref() protected featureContents!: FeatureContents;
  @Ref() protected featuresList!: FeaturesList;

  protected get isFeatureContentsOpened() {
    return mainStore.isFeatureContentsOpened;
  }

  protected get classObj() {
    return {
      'is-current': mainStore.contentsState === ContentsState.FEATURES,
      'is-noWebGL': this.isNoWebGL,
      'is-inited': this.isInited,
      'is-featureContentsOpened': this.isFeatureContentsOpened,
      'is-entered': this.isEntered,
      'is-leaved': this.isLeaved,
      'is-beforeEnter': this.isBeforeEnter,
      'is-beforeLeave': this.isBeforeLeave,
    }
  }

  @Emit('clickscroll')
  protected onClickScroll() {
    return this;
  }
  
  protected onClickClose() {
    mainStore.setIsFeatureContentsOpened(false);
    this.featuresList.enable();
  }

  public onResize() {
    this.featuresList.onResize();
    this.featureContents.onResize();
  }

  public getCanGoPrev() {
    // return this.featuresList.getIsAtBegin() && !mainStore.isFeatureContentsOpened;
    return !mainStore.isFeatureContentsOpened;
  }

  public getCanGoNext() {
    // return this.featuresList.getIsAtEnd() && !mainStore.isFeatureContentsOpened;
    return !mainStore.isFeatureContentsOpened;
  }

  public reset() {
    mainStore.setIsFeatureContentsOpened(false);
    this.featureContents.reset()
    this.featuresList.reset()
    this.featuresList.disable();
  }

  protected onClickItem(index: number) {
    mainStore.setIsFeatureContentsOpened(true);
    this.featureContents.setContents(this.listData[index]);
    this.featuresList.disable();
  }

  public focus() {
    (this.$el as HTMLElement).focus()
    this.featuresList.enable();
  }
}

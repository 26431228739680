


































































import { mainStore } from "@/store/main";
import { Vue, Component, Emit } from "vue-property-decorator";
import { SimpleLayoutMode } from "@/constants";

SimpleLayoutMode;
@Component
export default class Menu extends Vue {
  protected get isOpened() {
    return mainStore.isMenuOpened;
  }

  protected get classObj() {
    return {
      "is-opened":
        this.isOpened || mainStore.simpleLayoutMode === SimpleLayoutMode.L,
      [`is-${this.contentsState}`]: true,
      "is-noWebGL": mainStore.isNoWebGL,
      "is-uiAvailable": mainStore.isUIAvailable,
    };
  }

  @Emit("clickconcept")
  protected onClickConceptButton() {
    console.log("onClickConceptButton");
  }

  @Emit("clickfeatures")
  protected onClickFeatureButton() {
    console.log("onClickFeatureButton");
  }

  @Emit("clicklist")
  protected onClickListButton() {
    console.log("onClickListButton");
  }

  protected get contentsState() {
    return mainStore.contentsState;
  }

  public onResize() {}

  protected onBeforeEnter() {
    this.onResize();
  }
}


import { Component, Vue, Ref } from 'vue-property-decorator';

import gsap, { Expo } from 'gsap/src/gsap-core';

@Component
export default class ScrollContents extends Vue {
  protected _container!: HTMLElement;
  protected scrollerSize = 0;
  protected scrollSize = 0;
  protected scrollPos = 0;
  protected beforePos: [number, number] = [-1, -1];
  protected scrollDelta: [number, number] = [0, 0];
  protected isSwiping = false;
  protected isWheeling = false;
  protected wheelEneTimer = -1;
  protected swipeDirection: 'x' | 'y' | '' = '';
  protected maxDelta = 100;
  protected isLockedAlways = false;
  protected isAtBegin = false;
  protected isAtEnd = false;
  protected isAtBeginBackup = false;
  protected isAtEndBackup = false;
  protected isSwipingOverToBegin = true;
  protected isSwipingOverToEnd = false;
  protected isDisabled = true;

  protected scrollbarSize = 0;
  protected scrollbarScrollSize = 0;
  protected scrollbarKnobSize = 100;
  protected beforeScrollbarDragPos = 0;
  protected scrollbarKnobPos = 0;
  protected isScrollbarDragging = false;
  protected scrollbarButtonRepeatTimer = -1;

  protected scrollDeltaRatio = 1;
  protected isPassiveEventSupported = false;
  protected  = false;

  @Ref() protected scroller!: HTMLElement;

  @Ref() protected scrollbar!: HTMLElement;

  @Ref() protected container!: HTMLElement;

  @Ref() protected scrollbarKnob!: HTMLElement;

  created() {
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onMouseMoveScrollbarKnob = this.onMouseMoveScrollbarKnob.bind(this);
    this.onMouseUpScrollbarKnob = this.onMouseUpScrollbarKnob.bind(this);
    this.onTouchMoveScrollbarKnob = this.onTouchMoveScrollbarKnob.bind(this);
    this.onTouchEndScrollbarKnob = this.onTouchEndScrollbarKnob.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    if(this.$head.uaChecks.isFirefox) this.scrollDeltaRatio = 10;
  }

  async mounted() {
    this.isPassiveEventSupported = await this.$head.passiveEventPromise;

    this._container = this.container || this.$el as HTMLElement;
    this._container.addEventListener('touchstart', this.onTouchStart, this.isPassiveEventSupported? { passive: false }: false)
    this._container.addEventListener('wheel', this.onWheel, this.isPassiveEventSupported? { passive: false }: false)
    window.addEventListener('keyup', this.onKeyUp, { passive: false });

    if(this.scrollbar) {
      if(this.$head.hasHover) {
        this.scrollbarKnob.addEventListener('mousedown', this.onMouseDownScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
      } else {
        this.scrollbarKnob.addEventListener('touchstart', this.onTouchStartScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
      }
    }
  }

  beforeDestroy() {
    this._container.removeEventListener('touchmove', this.onTouchMove);
    this._container.removeEventListener('touchend', this.onTouchEnd);
    this._container.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('mousemove', this.onMouseMoveScrollbarKnob);
    window.removeEventListener('mouseup', this.onMouseUpScrollbarKnob);
    document.removeEventListener('touchmove', this.onTouchMoveScrollbarKnob);
    document.removeEventListener('touchend', this.onTouchEndScrollbarKnob);
    this.clearScrollbarButtonRepeatTimer();
    gsap.killTweensOf(this, { scrollPos: true });
  }

  protected get classObj() {
    return {
      'is-atEnd': !this.isScrollAvailable || this.isAtEnd,
      'is-atBegin': this.isAtBegin,
      'is-noScrollbar': !this.isScrollAvailable,
    }
  }

  protected get fixedStyleObj () {
    return {
      transform: `translateY(${this.scrollPos}px)`
    }
  }

  protected get scrollerStyleObj() {
    return {
      transform: `translateY(${-this.scrollPos}px)`
    }
  }

  protected get scrollbarKnobStyleObj(): { transform: string, width?: string, height?: string } {
    return {
      transform: `translateY(${this.scrollbarKnobPos}px)`,
      height: `${this.scrollbarKnobSize}px`,
    }
  }

  protected get isScrollAvailable() {
    return this.scrollSize > 0;
  }

  public async updateScrollbarPos(duration = 0.0001, scrollToPos = -10000) {
    if(!this.scrollbar) return;

    gsap.killTweensOf(this, { scrollbarKnobPos: true });
    await this.$nextTick();

    const scrollPos = (scrollToPos !== -10000)? scrollToPos: this.scrollPos;
    const scrollRatio = scrollPos / this.scrollSize;

    const to = Math.min(Math.max(0, this.scrollbarScrollSize * scrollRatio), this.scrollbarScrollSize);
    gsap.to(this, { duration, ease: Expo.easeOut, scrollbarKnobPos: to });
  }

  public async updateScrollbarSize() {
    if(!this.scrollbar || !this.scroller) return;
    await this.$nextTick();
    const ratio = this._container.offsetHeight / this.scroller.offsetHeight;
    this.scrollbarSize = this.scrollbar.offsetHeight;
    this.scrollbarKnobSize = this.scrollbarSize * ratio;
    this.scrollbarScrollSize = this.scrollbarSize - this.scrollbarKnobSize;
  }

  protected onMouseDownScrollbarKnob(e: MouseEvent) {
    this.beforeScrollbarDragPos = e.clientY;
    window.addEventListener('mousemove', this.onMouseMoveScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
    window.addEventListener('mouseup', this.onMouseUpScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
    if(e.cancelable) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  protected onTouchStartScrollbarKnob(e: TouchEvent) {
    this.beforeScrollbarDragPos = e.touches[0].clientY;
    document.addEventListener('touchmove', this.onTouchMoveScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
    document.addEventListener('touchend', this.onTouchEndScrollbarKnob, this.isPassiveEventSupported? { passive: false }: false);
    // if(e.cancelable) {
    //   e.preventDefault();
    //   e.stopImmediatePropagation();
    // }
  }

  protected onMouseMoveScrollbarKnob(e: MouseEvent) {
    if(this.isDisabled) return;
    this.isScrollbarDragging = true;
    const dy = e.clientY - this.beforeScrollbarDragPos;
    this.beforeScrollbarDragPos = e.clientY;
    this.scrollbarKnobPos = Math.min(Math.max(0, this.scrollbarKnobPos + dy), this.scrollbarScrollSize);
    const scrollRatio = this.scrollbarKnobPos / this.scrollbarScrollSize;

    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollSize * scrollRatio));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollPos: to });

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    if(e.cancelable) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  protected onTouchMoveScrollbarKnob(e: TouchEvent) {
    const touches = e.touches;

    if(touches.length !== 1) return;

    const touch = touches[0];

    this.isScrollbarDragging = true;
    const dy = touch.clientY - this.beforeScrollbarDragPos;
    this.beforeScrollbarDragPos = touch.clientY;
    this.scrollbarKnobPos = Math.min(Math.max(0, this.scrollbarKnobPos + dy), this.scrollbarScrollSize);
    const scrollRatio = this.scrollbarKnobPos / this.scrollbarScrollSize;

    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollSize * scrollRatio));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollPos: to });

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    // if(e.cancelable) {
    //   e.preventDefault();
    //   e.stopImmediatePropagation();
    // }
  }

  protected onMouseUpScrollbarKnob(e: MouseEvent) {
    this.isScrollbarDragging = false;
    window.removeEventListener('mousemove', this.onMouseMoveScrollbarKnob);
    window.removeEventListener('mouseup', this.onMouseUpScrollbarKnob);
    if(e.cancelable) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  protected onTouchEndScrollbarKnob(e: TouchEvent) {
    this.isScrollbarDragging = false;
    document.removeEventListener('touchmove', this.onTouchMoveScrollbarKnob);
    document.removeEventListener('touchend', this.onTouchEndScrollbarKnob);
    if(e.cancelable) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  public async resize() {
    if(!this.scroller || !this._container) return
    gsap.killTweensOf(this, { scrollPos: true });
    this.scrollerSize = this.scroller.offsetHeight;
    this.scrollSize = Math.max(0, this.scroller.offsetHeight - this._container.offsetHeight);
    if(this.scrollSize <= 0) {
      gsap.to(this, { duration: 0.8, ease: Expo.easeOut, scrollPos: 0 });
      this.isAtBegin = true;
      this.isAtEnd = false;
    }
    this.updateScrollbarSize();
    this.updateScrollbarPos();
  }

  public async onResize() {
    this.resize();
  }

  public onWheel(e: WheelEvent) {
    if(this.isDisabled) return;

    gsap.killTweensOf(this, { scrollPos: true });
    const delta = Math.min(this.maxDelta, e.deltaY * this.scrollDeltaRatio);
    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollPos + delta * 2));
    gsap.to(this, { duration: 0.8, ease: Expo.easeOut, scrollPos: to });
    this.updateScrollbarPos(0.8, to);

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    this.isSwipingOverToBegin = this.isAtBegin;
    this.isSwipingOverToEnd = this.isAtEnd;
     
    this.isWheeling = true;
    this.setWheelEndTimer();


    if(!this.isLockedAlways && (!this.isScrollAvailable || (e.deltaY > 0 && this.scrollPos === this.scrollSize) || (e.deltaY < 0 && this.scrollPos === 0))) return;
    e.stopImmediatePropagation();
    e.preventDefault()
  }

  public onTouchStart(e: TouchEvent) {
    this.swipeDirection = '';
    this.isSwiping = false;

    const touches = e.touches;
    const touch = e.touches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    this.scrollDelta = [0, 0];
    this.beforePos = [x, y];

    this.isSwipingOverToBegin = false;
    this.isSwipingOverToEnd = false;
    this.isAtBeginBackup = this.isAtBegin;
    this.isAtEndBackup = this.isAtEnd;

    if(touches.length === 1) {
      this._container.addEventListener('touchmove', this.onTouchMove, this.isPassiveEventSupported? { passive: false }: false);
      this._container.addEventListener('touchend', this.onTouchEnd, this.isPassiveEventSupported? { passive: false }: false);
    }
  }

  public onTouchMove(e: TouchEvent) {
    if(e.touches.length > 1 || this.isDisabled) return;

    // gsap.killTweensOf(this, { scrollPos: true });

    const touch = e.touches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    const pos: [number, number] = [x, y];

    if(this.beforePos[0] === -1) {
      this.beforePos = pos;
      return;
    }
    this.isSwiping = true;

    const dx = this.beforePos[0] - x;
    const dy = this.beforePos[1] - y;
    this.beforePos = [x, y];

    if(this.swipeDirection === '') {
      if(dx * dx > dy * dy * 4) {
        this.swipeDirection = 'x';
      } else {
        this.swipeDirection = 'y';
      }
    }

    if(this.swipeDirection === 'y') {
      this.scrollDelta = [dx, dy];
      const to =  Math.max(0, Math.min(this.scrollSize, this.scrollPos + dy));
      gsap.set(this, { ease: Expo.easeOut, scrollPos: to, overwrite: true });
      this.updateScrollbarPos();

      this.isAtBegin = to === 0;
      this.isAtEnd = to === this.scrollSize;

      const d = Math.abs(dy);
      
      // エッジにいる状態からエッジの方向へスワイプしようとしている
      const isPositionSame = to === this.scrollPos;
      this.isSwipingOverToBegin = this.isAtBeginBackup && this.isAtBegin && d > 0 && isPositionSame;
      this.isSwipingOverToEnd = this.isAtEndBackup && this.isAtEnd && d > 0 && isPositionSame;
      this.isAtBeginBackup = this.isSwipingOverToBegin;
      this.isAtEndBackup = this.isSwipingOverToEnd;

      if(e.cancelable) {
        e.preventDefault();
      }
    }

  }

  public onTouchEnd(e: TouchEvent) {
    // gsap.killTweensOf(this, { scrollPos: true });
    this.swipeDirection = '';
    this.beforePos = [-1, -1];
    this.isSwiping = false;
    this._container.removeEventListener('touchmove', this.onTouchMove);
    this._container.removeEventListener('touchend', this.onTouchEnd);
    if(e.touches.length > 1) return;

    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollPos + this.scrollDelta[1] * 10));
    gsap.to(this, { duration: 0.8, ease: Expo.easeOut, scrollPos: to, overwrite: true });
    this.updateScrollbarPos(0.8, to);

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

  }

  protected onKeyUp(e: KeyboardEvent) {
    if(this.isDisabled) return;

    gsap.killTweensOf(this, { scrollPos: true });
    let delta = 0;

    if(e.key === 'ArrowDown') {
      if(this.getIsAtEnd()) return
      
      // 下方向へスクロール
      delta = 100;
      
      } else if(e.key === 'ArrowUp') {
      if(this.getIsAtBegin()) return
      // 上方向へスクロール
      delta = -100;

    }

    
    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollPos + delta));
    gsap.to(this, { duration: 0.8, ease: Expo.easeOut, scrollPos: to });
    this.updateScrollbarPos(0.8, to);

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    this.isSwipingOverToBegin = this.isAtBegin;
    this.isSwipingOverToEnd = this.isAtEnd;

    e.preventDefault();
    e.stopImmediatePropagation()
  }

  public getIsAtBegin() {
    return !this.isScrollAvailable || this.isAtBegin && this.isSwipingOverToBegin
  }
  public getIsAtEnd() {
    return !this.isScrollAvailable || this.isAtEnd && this.isSwipingOverToEnd
  }

  public getContainerWidth() {
    return this._container.offsetWidth;
  }

  public reset() {
    this.isAtBegin = true;
    this.isAtEnd = false;
    this.scrollPos = 0;
    this.scrollbarKnobPos = 0;
    this.isSwipingOverToBegin = true;
    this.isSwipingOverToEnd = false;
    this.isAtBeginBackup = false;
    this.isAtEndBackup = false;
    this.updateScrollbarPos();
  }

  public async onBeforeEnter() {
    // this.reset()
    window.setTimeout(()=> {
      this.onResize();
    }, 40)
  }

  public onAfterEnter() {
    this.onResize();
  }

  public onBeforeLeave() {
  }

  public onAfterLeave() {
    // this.reset()
  }

  protected clearScrollbarButtonRepeatTimer() {
    if(this.scrollbarButtonRepeatTimer) {
      window.clearInterval(this.scrollbarButtonRepeatTimer);
    }
  }

  protected onMouseDownScrollbarBeforeButton() {
    gsap.killTweensOf(this, { scrollPos: true });
    this.onBeforeButton();

    this.clearScrollbarButtonRepeatTimer();
    this.scrollbarButtonRepeatTimer = window.setInterval(()=> {
      this.onBeforeButton();
    }, 50);
  }

  protected onMouseUpScrollbarBeforeButton() {
    this.clearScrollbarButtonRepeatTimer();
  }

  protected onBeforeButton() {
    let scrollRatio = this.scrollbarKnobPos / this.scrollbarScrollSize;
    scrollRatio = Math.max(0, scrollRatio - 0.1);

    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollSize * scrollRatio));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollPos: to });
    this.updateScrollbarPos(0.8, to);

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    if(to === 0 || to === this.scrollSize) this.clearScrollbarButtonRepeatTimer();
  }

  protected onMouseDownScrollbarAfterButton() {
    gsap.killTweensOf(this, { scrollPos: true });
    this.onAfterButton();

    this.clearScrollbarButtonRepeatTimer();
    this.scrollbarButtonRepeatTimer = window.setInterval(()=> {
      this.onAfterButton();
    }, 50);
  }

  protected onMouseUpScrollbarAfterButton() {
    this.clearScrollbarButtonRepeatTimer();
  }

  protected onAfterButton() {
    let scrollRatio = this.scrollbarKnobPos / this.scrollbarScrollSize;
    scrollRatio = Math.min(1.0, scrollRatio + 0.1);

    const to =  Math.max(0, Math.min(this.scrollSize, this.scrollSize * scrollRatio));
    gsap.to(this, 0.8, { ease: Expo.easeOut, scrollPos: to });
    this.updateScrollbarPos(0.8, to);

    this.isAtBegin = to === 0;
    this.isAtEnd = to === this.scrollSize;

    if(to === 0 || to === this.scrollSize) this.clearScrollbarButtonRepeatTimer();
  }

  protected get isScrollbarBeforeButtonDisabled() {
    return this.isAtBegin;
  }
  protected get isScrollbarAfterButtonDisabled() {
    return this.isAtEnd;
  }

  public setIsLockedAlways(isLockedAlways: boolean) {
    this.isLockedAlways = isLockedAlways;
  }

  protected setWheelEndTimer() {
    this.clearWheelEndTimer();
    this.wheelEneTimer = window.setTimeout(()=> {
      this.isWheeling = false;
    }, 200);
  }

  protected clearWheelEndTimer() {
    if(this.wheelEneTimer) window.clearTimeout(this.wheelEneTimer);
  }

  public enable() {
    this.isDisabled = false;
  }
  public disable() {
    this.isDisabled = true;
  }
}
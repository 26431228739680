


















import { mainStore } from '@/store/main';
import { Vue, Component, Ref, Emit } from 'vue-property-decorator';

@Component
export default class Menu extends Vue {
  protected get classObj() {
    return {
      'is-menuOpened': this.isMenuOpened,
      [`is-${this.contentsState}`]: true,
      'is-noWebGL': mainStore.isNoWebGL,
    }
  }

  protected get contentsState() {
    return mainStore.contentsState;
  }
  
  protected get isMenuOpened() {
    return mainStore.isMenuOpened
  }
  
  protected get isNoWebGL() {
    return mainStore.isNoWebGL;
  }

  protected get ariaExpanded() {
    return this.isMenuOpened? 'true': 'false'
  }
  
  protected onClick() {
    mainStore.setIsMenuOpened(!this.isMenuOpened)
  }
}


import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry'    ;
import { RawShaderMaterial   } from 'three/src/materials/RawShaderMaterial' ;
import { Vector2             } from 'three/src/math/Vector2'                ;
import { Vector4             } from 'three/src/math/Vector4'                ;
import { Color               } from 'three/src/math/Color'                  ;
import { Material            } from 'three/src/materials/Material'          ;

import gsap, { Expo, Linear } from 'gsap/src/gsap-core';
import { GUI } from 'dat.gui';

import WebGLMesh from './WebGLMesh';
import { mainStore } from '@/store/main';
import { ContentsIndex, ContentsState, ContentsStateProp } from '@/constants';

const AnimationFactorKeys = ['gridAnimationValue', 'listGridAnimationValue', 'slideAnimationValue'] as const;

export default class BgCommonMesh extends WebGLMesh {
  protected timeline!: gsap.core.Timeline;
  protected textureIndex0 = 4;
  protected textureIndex1 = 4;

  constructor(geometry: PlaneBufferGeometry) {
    super(geometry);
    this.matrixAutoUpdate = false;
  }

  protected initMaterial() {
    (this.material as Material)?.dispose();

    this.material = new RawShaderMaterial({
      vertexShader: require('./glsl/plane.vert').default,
      fragmentShader: require('./glsl/bgCommon.frag').default,
      depthTest: false,
      depthWrite: false,
      transparent: true,
      uniforms: {
        resolution: { value: new Vector2 },
        isEntering: { value: 1 },
        animationDir: { value: 1 },
        seeds: { value: new Vector4(Math.random(), Math.random(), Math.random(), Math.random()) },
        kvCoverColor: { value: new Color(0x3c3c3c) },
        isEnabled: { value: 0 },
        kvCoverAnimationValue: { value: 0 },
        
        texture0: { value: null },
        uvSize0: { value: new Vector2() },
        uvOffset0: { value: new Vector2() },
        
        texture1: { value: null},
        uvSize1: { value: new Vector2() },
        uvOffset1: { value: new Vector2() },

        texture0Repeat: { value: 0 },
        texture1Repeat: { value: 0 },
        texture1Factor: { value: 1 },

        gridAnimationValue: { value: 0 },
        gridAnimationValueFactor: { value: 0 },

        listGridAnimationValue: { value: 0 },
        listGridAnimationValueFactor: { value: 0 },

        slideAnimationValue: { value: 0 },
        slideAnimationValueFactor: { value: 0 },

        logoTexture: { value: null},
        logoUVOffset: { value: new Vector2() },
        logoUVSize: { value: new Vector2() },
        logoUVOffsetToU: { value: 0},

        gridResolution: { value: new Vector2(1, 1) },
        listGridWidth: { value: 1 },
        listGridOffset: { value: 1 },
      }
    })
  }


  public initDatGUI(datGUI: GUI) {
    const material = this.material as RawShaderMaterial
    const obj = {
      gridAnimationValue: material.uniforms.gridAnimationValue.value,
      listGridAnimationValue: material.uniforms.listGridAnimationValue.value,
      slideAnimationValue: material.uniforms.slideAnimationValue.value,
      isEntering: material.uniforms.isEntering.value,
      kvCoverAnimationValue: material.uniforms.kvCoverAnimationValue.value,
    }

    const folder = datGUI.addFolder('common')
    folder.add(obj, 'gridAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.gridAnimationValue.value = value;
    })
    folder.add(obj, 'listGridAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.listGridAnimationValue.value = value;
    })
    folder.add(obj, 'slideAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.slideAnimationValue.value = value;
    })
    folder.add(obj, 'isEntering', [-1, 1]).onChange((value)=> {
      material.uniforms.isEntering.value = value;
    })
    folder.add(obj, 'kvCoverAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.kvCoverAnimationValue.value = value;
    })
    folder.open()
  }

  public async onResize(width = 1, height = 1, pixelRatio = 1) {
    super.onResize(width, height, pixelRatio);

    if(!this.material || this.texturesData.length === 0) return;
    const material = this.material as RawShaderMaterial


    if(this.width / this.height < 1) {
      // 縦長
      material.uniforms.gridResolution.value.x = Math.round(this.width * 0.5 * this.pixelRatio);
      material.uniforms.gridResolution.value.y = Math.round(this.height * 0.333333 * this.pixelRatio);

    } else {
      // 横長
      material.uniforms.gridResolution.value.x = Math.round(this.width * 0.25 * this.pixelRatio);
      material.uniforms.gridResolution.value.y = Math.round(this.height * 0.5 * this.pixelRatio);
    }

    this.setTextureParams(0, this.textureIndex0);
    this.setTextureParams(1, this.textureIndex1);

    // ロゴのテクスチャのuvSize, uvOffset
    const logoTd = this.texturesData[this.texturesData.length - 1].S
    const uvSize = new Vector2(1, 1);
    const uvOffset = new Vector2(0, 0);
    const scale = height / logoTd.height;
    uvSize.x = width / logoTd.width / scale;
    uvOffset.x = (1 - uvSize.x) * 0.5;
    material.uniforms.logoUVOffset.value = uvOffset;
    material.uniforms.logoUVSize.value = uvSize;
    material.uniforms.logoUVOffsetToU.value = uvOffset.x + uvSize.x - 0.307;

    material.needsUpdate = true;
  }

  public async loadTextures(loadItemCallback: () => void = ()=> {}, maxTextureSize = 2048) {
    await super.loadTextures(loadItemCallback, maxTextureSize);

    if(!this.material || this.texturesData.length === 0) return;
    const material = this.material as RawShaderMaterial
    const logoTd = this.texturesData[this.texturesData.length - 1][mainStore.simpleLayoutMode];
    material.uniforms.logoTexture.value = logoTd.texture
  }

  protected async updateMaterial() {
    if(!this.material) return;
    const material = this.material as RawShaderMaterial

    const promises: Promise<void>[] = [];

    if(this.textureIndex0 === -1) {
      // KV
      material.uniforms.texture0.value = null;
      promises.push(Promise.resolve())
    } else {
      this.setTextureParams(0, this.textureIndex0);
      const td = this.texturesData[this.textureIndex0][mainStore.simpleLayoutMode];
      promises.push((td.loadPromise as Promise<any>).then(()=> {
        material.uniforms.texture0.value = td.texture;
      }))
    }
    
    if(this.textureIndex1 === -1) {
      // KV
      material.uniforms.texture1.value = null;
      promises.push(Promise.resolve())
    } else {
      this.setTextureParams(1, this.textureIndex1);
      const td = this.texturesData[this.textureIndex1][mainStore.simpleLayoutMode];
      promises.push((td.loadPromise as Promise<any>).then(()=> {
        material.uniforms.texture1.value = td.texture;
      }))
    }

    await Promise.all(promises);
    material.needsUpdate = true;
  }

  public playVideo() { this.customTextures[0].play() }

  public pauseVideo() { this.customTextures[0].pause() }

  protected updateSeeds() {
    const material = this.material as RawShaderMaterial
    material.uniforms.seeds.value.x = Math.random();
    material.uniforms.seeds.value.y = Math.random();
    material.uniforms.seeds.value.z = Math.random();
    material.uniforms.seeds.value.w = Math.random();
  }

  protected animate(
    animationFactorKey: 'gridAnimationValue' | 'listGridAnimationValue' | 'slideAnimationValue',
    fromValue: number,
    toValue: number,
    duration = 1.8,
    onComplete: ()=> void = ()=> {},
    animateTexture1Factor = false,
    fromKV = false,
    toKV = false,
  ) {
    this.isAnimating = true;
    const material = this.material as RawShaderMaterial

    this.updateSeeds();

    for (const key of AnimationFactorKeys) {
      if(animationFactorKey === key) {
        material.uniforms[key].value = 1;
        material.uniforms[`${key}Factor`].value = 1;
      } else {
        material.uniforms[key].value = 0;
        material.uniforms[`${key}Factor`].value = 0;
      }
    }

    const timeline = gsap.timeline({
      onComplete: ()=> {
        this.isAnimating = false;
        if(!toKV) onComplete()
      },
    })
    .fromTo(
      material.uniforms[animationFactorKey],
      { value: fromValue },
      {
        duration,
        value: toValue,
        ease: Linear.easeNone,
      },
      0
    )
    .fromTo(
      material.uniforms.kvCoverAnimationValue,
      { value: fromKV? 1: 0 },
      {
        duration,
        value: toKV? 1: 0,
        ease: Linear.easeNone,
      },
      0
    )
    .fromTo(
      material.uniforms.texture1Factor,
      { value: animateTexture1Factor? 0: 1 },
      {
        duration,
        value: 1,
        ease: Expo.easeInOut,
      },
      0
    )
    if(toKV) {
      timeline.add(()=> onComplete(), 0.8)
    }
  }

  public setListGridParams(listGridWidth: number, listGridOffset: number) {
    const material = this.material as RawShaderMaterial
    material.uniforms.listGridWidth.value = listGridWidth * this.pixelRatio;
    material.uniforms.listGridOffset.value = listGridOffset * this.pixelRatio;
  }

  public enter(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: ()=> void = ()=> {},
    onBeforeEnter: ()=> void = ()=> {}
  ) {
    onBeforeEnter();

    const material = this.material as RawShaderMaterial
    
    const beforeContentsIndex = ContentsIndex[beforeContentsState];
    const contentsIndex = ContentsIndex[contentsState];

    // isEntering
    material.uniforms.animationDir.value = beforeContentsIndex > contentsIndex? -1: 1;
    material.uniforms.isEntering.value = 1;

    this.textureIndex0 = beforeContentsIndex - 1;
    if(this.textureIndex0 < 0) this.textureIndex0 = 4
    this.textureIndex1 = contentsIndex - 1;
    if(this.textureIndex1 < 0) this.textureIndex1 = 4
    this.updateMaterial();

    const fromKV = beforeContentsState === ContentsState.KV;

    if(contentsState === ContentsState.LIST) {
      // listへ
      material.uniforms.texture0Repeat.value = 1;
      material.uniforms.texture1Repeat.value = 0;
      this.animate('listGridAnimationValue', 0, 1, 1.8, onComplete, false, fromKV);

    } else if(beforeContentsState === ContentsState.LIST) {
      // listから
      material.uniforms.texture0Repeat.value = 1;
      material.uniforms.texture1Repeat.value = 0;
      this.animate('listGridAnimationValue', 0, 1, 1.8, onComplete, false, fromKV);

    } else if(beforeContentsState === ContentsState.KV) {
      // KVから
      material.uniforms.texture0Repeat.value = 0;
      material.uniforms.texture1Repeat.value = 1;
      this.animate('gridAnimationValue', 0, 1, 1.8, onComplete, false, fromKV);

    } else if(contentsState === ContentsState.FEATURES) {
      // featureへ
      material.uniforms.texture0Repeat.value = 1;
      material.uniforms.texture1Repeat.value = 0;
      this.animate('slideAnimationValue', 0, 1, 1.8, onComplete, false, fromKV);

    } else if(beforeContentsState === ContentsState.FEATURES) {
      // featureから
      material.uniforms.texture0Repeat.value = 1;
      material.uniforms.texture1Repeat.value = 0;
      this.animate('slideAnimationValue', 0, 1, 1.8, onComplete, false, fromKV);

    } else {
      // その他全部 grid
      material.uniforms.texture0Repeat.value = 1;
      material.uniforms.texture1Repeat.value = 1;
      this.animate('gridAnimationValue', 0, 1, 1.8, onComplete, true, fromKV);
    }
  }

  public leave(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: ()=> void = ()=> {}
  ) {
    const material = this.material as RawShaderMaterial

    const beforeContentsIndex = ContentsIndex[beforeContentsState];
    const contentsIndex = ContentsIndex[contentsState];

    // isEntering
    material.uniforms.animationDir.value = beforeContentsIndex > contentsIndex? -1: 1;
    material.uniforms.isEntering.value = 0;

    // enterと逆なので注意
    this.textureIndex0 = contentsIndex - 1;
    if(this.textureIndex0 < 0) this.textureIndex0 = 4
    this.textureIndex1 = beforeContentsIndex - 1;
    if(this.textureIndex1 < 0) this.textureIndex1 = 4
    this.updateMaterial();

    if(contentsState === ContentsState.KV) {
      // KVに向かうときのleave
      if(beforeContentsState === ContentsState.LIST) {
        // list gird
        material.uniforms.texture0Repeat.value = 1;
        material.uniforms.texture1Repeat.value = 1;
        this.animate('listGridAnimationValue', 1, 0, 1.8, onComplete, false, false, true);

      } else {
        // grid
        material.uniforms.texture0Repeat.value = 1;
        material.uniforms.texture1Repeat.value = 1;
        this.animate('gridAnimationValue', 1, 0, 1.8, onComplete, false, false, true);
      }
    } else {
      // KV以外に向かうときは、leaveとenterは重なるアニメーションなので、leaveは何もしない (アニメーションの制御はenterで)
        onComplete()
    }
  }
}


export function capitalize(txt) {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
}

export function parseGetParams() {
  const params = {}
  location.search.replace('?', '').split('&').map((str)=> {
    const [ key, value ] = str.split('=')
    params[key] = value;
    return { key, value };
  });
  return params;
}


import { Component, Vue, Ref, Prop, Emit } from 'vue-property-decorator';
import { mainStore } from '@/store/main';

import KVMesh from './webGL/KVMesh';
import CommonMesh from './webGL/CommonMesh';
import { ContentsStateProp } from '@/constants';


const LOGO_ASPECT_RATIO = 240 / 217 * 0.5;

@Component
export default class Section extends Vue {
  protected width = 0;
  protected height = 0;
  protected logoWidth = 0;
  protected isInited = false;
  protected isBeforeLeave = false;
  protected isLeaved = false;
  protected isBeforeEnter = false;
  protected isEntered = false;
  protected canScrollNext = false;
  protected common = false;

  protected glKVMesh!: KVMesh;
  protected glCommonMesh!: CommonMesh;

  protected get classObj() {
    return {
      'is-noWebGL': this.isNoWebGL,
      'is-inited': this.isInited,
      'is-entered': this.isEntered,
      'is-leaved': this.isLeaved,
      'is-beforeEnter': this.isBeforeEnter,
      'is-beforeLeave': this.isBeforeLeave,
    }
  }

  protected get isNoWebGL() {
    return mainStore.isNoWebGL;
  }

  mounted() {
  }
  
  public onResize() {
    const el = this.$el as HTMLElement;
    if(!el) return;

    this.width = el.offsetWidth;
    this.height = el.offsetHeight;

    this.logoWidth = (this.height + 2) * LOGO_ASPECT_RATIO;
  }

  protected get logoLeftStyleObj() {
    return {
      width: `${this.logoWidth}px`
    }
  }
  
  protected get logoRightStyleObj() {
    return {
      width: `${this.logoWidth}px`
    }
  }

  public async init() {
    this.isInited = true;
  }

  protected onBeforeEnter() {
  }

  public async enter(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: ()=> void = ()=> {}
  ) {
    this.isBeforeLeave = false;
    this.isBeforeEnter = true;
    this.isLeaved = false;
    this.glCommonMesh.enter(beforeContentsState, contentsState, onComplete, this.onBeforeEnter)

    await this.$nextTick()
    window.setTimeout(()=> {
      this.isEntered = true;
    }, 10)
  }

  public async leave(
    beforeContentsState: ContentsStateProp,
    contentsState: ContentsStateProp,
    onComplete: ()=> void = ()=> {}
  ) {
    this.isBeforeLeave = true;
    this.isBeforeEnter = false;
    this.isEntered = false;
    this.isLeaved = true;
    this.glCommonMesh.leave(beforeContentsState, contentsState, onComplete)
    
  }

  public setGLKVMesh(glKVMesh: KVMesh) {
    this.glKVMesh = glKVMesh;
  }
  
  public setGLCommonMesh(glCommonMesh: CommonMesh) {
    this.glCommonMesh = glCommonMesh;
  }

  public getCanGoPrev() {
    return true;
  }
  
  public getCanGoNext() {
    return true;
  }

  public reset() {}

  public focus() {
    (this.$el as HTMLElement).focus()
  }
}

import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry'    ;
import { RawShaderMaterial   } from 'three/src/materials/RawShaderMaterial' ;
import { Vector2             } from 'three/src/math/Vector2'                ;
import { Vector4             } from 'three/src/math/Vector4'                ;
import { Color             } from 'three/src/math/Color'                ;
import { Material            } from 'three/src/materials/Material'          ;

import gsap, { Sine, Cubic, Expo, Linear } from 'gsap/src/gsap-core';
import { GUI } from 'dat.gui';

import WebGLMesh from './WebGLMesh';
import { mainStore } from '@/store/main';

export default class KVMesh extends WebGLMesh {
  protected textureIndex = 0;
  protected nextTextureIndex = 0;
  protected timeline!: gsap.core.Timeline;

  constructor(geometry: PlaneBufferGeometry) {
    super(geometry);
    this.matrixAutoUpdate = false;
  }

  protected initMaterial() {
    (this.material as Material)?.dispose();

    this.material = new RawShaderMaterial({
      vertexShader: require('./glsl/plane.vert').default,
      fragmentShader: require('./glsl/kv.frag').default,
      depthTest: false,
      depthWrite: false,
      transparent: true,
      uniforms: {
        resolution: { value: new Vector2 },
        
        texture0: { value: null },
        uvSize0: { value: new Vector2() },
        uvOffset0: { value: new Vector2() },
        scaledUVSize0: { value: new Vector2() },
        scaledUVOffset0: { value: new Vector2() },
        
        texture1: { value: null},
        uvSize1: { value: new Vector2() },
        uvOffset1: { value: new Vector2() },
        scaledUVSize1: { value: new Vector2() },
        scaledUVOffset1: { value: new Vector2() },
        
        bgColor: { value:  new Color(0x3c3c3c) },
        
        scaleAnimationValue: { value: 0 },

        textureSwapAnimationValue: { value: 0 },
        leaveAnimationValue: { value: 0 },
        isEntering: { value: 0 },
        isEnabled: { value: 1 },
        
        fadeInValue: { value: 0 },
        logoEdges: { value: new Vector4() },
        gridResolution: { value: new Vector2(1, 1) },
        seeds: { value: new Vector4(Math.random(), Math.random(), Math.random(), Math.random()) },
      }
    })
  }

  public animateScale() {
    const material = this.material as RawShaderMaterial
    this.isAnimating = true;
    this.timeline = gsap.timeline({ onComplete: ()=> {
      this.isAnimating = false;
    }})
    .to(material.uniforms.scaleAnimationValue, { duration: 2, value: 1, ease: Expo.easeOut })

    return this.timeline
  }

  public animateToNextImg(textureIndex: number) {
    const material = this.material as RawShaderMaterial

    this.nextTextureIndex = textureIndex
    
    const td0 = this.texturesData[this.textureIndex][mainStore.simpleLayoutMode];
    material.uniforms.texture0.value = td0.texture
    this.setTextureParams(0, this.textureIndex);
    
    const td1 = this.texturesData[this.nextTextureIndex][mainStore.simpleLayoutMode];
    material.uniforms.texture1.value = td1.texture
    this.setTextureParams(1, this.nextTextureIndex);
    
    
    material.uniforms.textureSwapAnimationValue.value = 0
    this.updateSeeds();
    material.needsUpdate = true;

    this.isAnimating = true;
    this.timeline = gsap.timeline({ onComplete: ()=> {
      this.isAnimating = false;
      this.textureIndex = textureIndex;
    }})
    .to(material.uniforms.textureSwapAnimationValue, { duration: 1.2, value: 1, ease: Expo.easeInOut })

    return this.timeline;
  }

  public initDatGUI(datGUI: GUI) {
    const material = this.material as RawShaderMaterial
    const obj = {
      textureSwapAnimationValue: material.uniforms.textureSwapAnimationValue.value,
      leaveAnimationValue: material.uniforms.leaveAnimationValue.value,
      scaleAnimationValue: material.uniforms.scaleAnimationValue.value,
    }

    const folder = datGUI.addFolder('kv')
    folder.add(obj, 'textureSwapAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.textureSwapAnimationValue.value = value;
    })
    folder.add(obj, 'leaveAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.leaveAnimationValue.value = value;
    })
    folder.add(obj, 'scaleAnimationValue', 0, 1, 0.01).onChange((value)=> {
      material.uniforms.scaleAnimationValue.value = value;
    })
    folder.open()
  }

  public async onResize(width = 1, height = 1, pixelRatio = 1) {
    super.onResize(width, height, pixelRatio);

    if(!this.material || this.texturesData.length === 0) return;
    const material = this.material as RawShaderMaterial
    const td0 = this.texturesData[this.textureIndex][mainStore.simpleLayoutMode];

    if(this.width / this.height < 1) {
      // 縦長
      material.uniforms.gridResolution.value.x = Math.round(this.width * 0.5 * this.pixelRatio);
      material.uniforms.gridResolution.value.y = Math.round(this.height * 0.333333 * this.pixelRatio);

    } else {
      // 横長
      material.uniforms.gridResolution.value.x = Math.round(this.width * 0.25 * this.pixelRatio);
      material.uniforms.gridResolution.value.y = Math.round(this.height * 0.5 * this.pixelRatio);
    }

    this.setTextureParams(0, this.textureIndex);
    this.setTextureParams(1, this.nextTextureIndex);

    material.needsUpdate = true;
  }

  public fadeIn() {
    const material = this.material as RawShaderMaterial
    return gsap.to(material.uniforms.fadeInValue, { duration: 0.4, value: 1, ease: Linear.easeNone })
  }

  protected async updateMaterial() {
    if(!this.material) return;
    const material = this.material as RawShaderMaterial
    const td0 = this.texturesData[this.textureIndex][mainStore.simpleLayoutMode];
    const td1 = this.texturesData[this.nextTextureIndex][mainStore.simpleLayoutMode];

    this.setTextureParams(0, this.textureIndex);
    this.setTextureParams(1, this.nextTextureIndex);
    
    td0.loadPromise?.then(()=> {
      material.uniforms.texture0.value = td0.texture;
    })

    td1.loadPromise?.then(()=> {
      material.uniforms.texture1.value = td1.texture;
    })
    
    material.needsUpdate = true;
  }

  public update(time = 0) {

  }

  public setLogoEdges(top: number, right: number, bottom: number, left: number) {
    if(!this.material || this.texturesData.length === 0) return;
    const material = this.material as RawShaderMaterial
    material.uniforms.logoEdges.value.x = top * this.pixelRatio;
    material.uniforms.logoEdges.value.y = right * this.pixelRatio;
    material.uniforms.logoEdges.value.z = bottom * this.pixelRatio;
    material.uniforms.logoEdges.value.w = left * this.pixelRatio;
  }

  protected updateSeeds() {
    const material = this.material as RawShaderMaterial
    material.uniforms.seeds.value.x = Math.random();
    material.uniforms.seeds.value.y = Math.random();
    material.uniforms.seeds.value.z = Math.random();
    material.uniforms.seeds.value.w = Math.random();
  }

  public leave(onComplete: ()=> void = ()=> {}) {
    if(!this.material || this.texturesData.length === 0 || this.isAnimating) return;
    const material = this.material as RawShaderMaterial
    this.isAnimating = true;
    this.updateSeeds();
    material.uniforms.isEntering.value = 0;
    this.timeline?.kill()
    this.timeline = gsap.timeline({
      onComplete: ()=> this.disable()
    })
    .to(material.uniforms.leaveAnimationValue, { duration: 1.8, value: 1.01, ease: Linear.easeNone, overwrite: true })
    .add(()=> {
      this.isAnimating = false;
      onComplete();
    }, 0.8)
  }
  
  public enter(onComplete: ()=> void = ()=> {}) {
    if(!this.material || this.texturesData.length === 0 || this.isAnimating) return;
    const material = this.material as RawShaderMaterial
    this.isAnimating = true;
    this.enable();
    this.updateSeeds();
    material.uniforms.isEntering.value = 1;
    this.timeline?.kill()
    this.timeline = gsap.timeline()
    .to(material.uniforms.leaveAnimationValue, { duration: 1.8, value: 0, ease: Linear.easeNone, overwrite: true })
    .add(()=> {
      this.isAnimating = false;
      onComplete();
    }, 1)
  }

  protected setTextureParams(uniformTextureIndex: number, textureIndex: number) {
    if(textureIndex === -1) return
    const customTexture = this.customTextures[textureIndex];
    const material = this.material as RawShaderMaterial
    
    const uvOffset = customTexture.getUVOffset()
    const uvSize = customTexture.getUVSize()
    
    material.uniforms[`uvOffset${uniformTextureIndex}`].value = uvOffset
    material.uniforms[`uvSize${uniformTextureIndex}`].value = uvSize

    const animationScale = (1.0 + 0.1 / Math.min(uvSize.x, uvSize.y));
    
    const scaledUVSize = uvSize.clone();
    scaledUVSize.multiplyScalar(1 / animationScale);

    const scaledUVOffset = uvOffset.clone();
    scaledUVOffset.x += (uvSize.x - scaledUVSize.x) * 0.5;
    scaledUVOffset.y += (uvSize.y - scaledUVSize.y) * 0.5;

    material.uniforms[`scaledUVOffset${uniformTextureIndex}`].value = scaledUVOffset
    material.uniforms[`scaledUVSize${uniformTextureIndex}`].value = scaledUVSize

  }
}


















































































import { Vue, Component, Ref } from "vue-property-decorator";
import gsap, { Expo, Cubic } from "gsap/src/gsap-core";
import axios, { AxiosResponse, AxiosError } from "axios";

// ------------------------------
// object-fit pollyfill
// ------------------------------
const objectFitImages = require("object-fit-images");
const objectFitVideos = require("object-fit-videos");

import { mainStore } from "@/store/main";

import {
  ContentsState,
  ContentsStateProp,
  LayoutMode,
  SimpleLayoutMode,
} from "@/constants";

import GlobalLoading from "@/components/GlobalLoading.vue";
import Menu from "@/components/Menu.vue";
import MenuButton from "@/components/MenuButton.vue";
import KV from "@/components/KV.vue";
import Concept from "@/components/Concept.vue";
import Features from "@/components/Features.vue";
import List from "@/components/List.vue";
import Footer from "@/components/Footer.vue";

import WebGLBase from "@/components/webGL/WebGLBase";
import BgGL from "./components/webGL/BgGL";

import {
  FeaturesData,
  KVPopUpData,
  ListItemData,
  TextureData,
} from "./@types/common";

import { GUI } from "dat.gui";
import Section from "./components/Section";

// テストサーバーのときはtrueにする (本番環境ではfalseにするのを忘れないように！)
const IS_DEV_SERVER = false;

const API_URL_BASE =
  process.env.NODE_ENV === "production" && IS_DEV_SERVER
    ? "https://12-office.com"
    : "";

const FEATURES_DATA_API = `${API_URL_BASE}/admin/wp-json/wp/v2/features/`;
const KV_POPUP_DATA_API = `${API_URL_BASE}/admin/wp-json/wp/v2/mvpopup/`;
// const FEATURES_DATA_API = '/data/features.json';
// const KV_POPUP_DATA_API = '/data/mvpopup.json';

const LIST_DATA_JSON = "/data/list.json";

const KV_TEXTURES: TextureData[] = [
  {
    S: {
      src: "kv/01S.jpg",
      width: 828,
      height: 1400,
      posX: "center",
      posY: "center",
    },
    L: {
      src: "kv/01.jpg",
      width: 1600,
      height: 900,
      posX: "right",
      posY: "center",
      hasL: true,
    },
  },
  {
    S: {
      src: "kv/02S.jpg",
      width: 828,
      height: 1400,
      posX: "center",
      posY: "center",
    },
    L: {
      src: "kv/02.jpg",
      width: 1600,
      height: 900,
      posX: "center",
      posY: "center",
      hasL: true,
    },
  },
  {
    S: {
      src: "kv/03S.jpg",
      width: 828,
      height: 1400,
      posX: "center",
      posY: "bottom",
    },
    L: {
      src: "kv/03.jpg",
      width: 1600,
      height: 900,
      posX: "center",
      posY: "center",
      hasL: true,
    },
  },
  {
    S: {
      src: "kv/04S.jpg",
      width: 828,
      height: 1400,
      posX: "left",
      posY: "top",
    },
    L: {
      src: "kv/04.jpg",
      width: 1600,
      height: 900,
      posX: "left",
      posY: "top",
      hasL: true,
    },
  },
];

const BG_TEXTURES: TextureData[] = [
  {
    S: {
      src: "concept/concept.mp4",
      width: 1920,
      height: 1080,
      common: true,
      type: "video",
    },
    L: {
      src: "concept/concept.mp4",
      width: 1920,
      height: 1080,
      common: true,
      type: "video",
    },
  },
  {
    S: {
      src: "features/featuresBgS.jpg",
      width: 828,
      height: 1400,
      posY: "bottom",
    },
    L: {
      src: "features/featuresBg.jpg",
      width: 1600,
      height: 900,
      posX: "right",
      hasL: true,
    },
  },
  {
    S: { src: "gl/listBg.png", width: 2, height: 2, common: true },
    L: { src: "gl/listBg.png", width: 2, height: 2, common: true },
  },
  {
    S: { src: "footer/footerBgS.png", width: 2, height: 2 },
    L: { src: "footer/footerBg.jpg", width: 1600, height: 900, hasL: true },
  },
  {
    S: { src: "gl/e.png", width: 2, height: 2 },
    L: { src: "gl/e.png", width: 2, height: 2 },
  },
  {
    S: { src: "gl/logo.png", width: 2048, height: 1024, common: true },
    L: { src: "gl/logo.png", width: 2048, height: 1024, common: true },
  },
];

@Component({
  components: {
    GlobalLoading,
    Menu,
    MenuButton,
    KV,
    Concept,
    Features,
    List,
    Footer,
  },
})
export default class App extends Vue {
  public bodyEl!: HTMLBodyElement;
  public htmlEl!: HTMLHtmlElement;

  public scroller!: HTMLElement;
  public scrollerSpacer!: HTMLElement;
  public scrollHeight = 0;

  protected mqL!: MediaQueryList;
  protected mqL2!: MediaQueryList;
  protected mqLL!: MediaQueryList;
  protected mqM!: MediaQueryList;
  protected mqM2!: MediaQueryList;
  protected mqSS!: MediaQueryList;

  protected resizeTimer: number | null = null;
  protected scrollToTweenObj!: { value: number };

  protected totalLoadCount = 0;
  protected loadedCount = 0;

  protected contents!: { [T in ContentsStateProp]: Section };

  protected kvPopUpData: KVPopUpData | null = null;
  protected featuresData: FeaturesData[] = [];
  protected listData: ListItemData[] = [];

  protected bgGL!: BgGL;
  protected touchStartPoint: { x: number; y: number } = { x: -1, y: -1 };
  protected touchPoint: { x: number; y: number } = { x: -1, y: -1 };
  protected isSwiping = false;

  protected scrollDeltaRatio = 1;
  protected isLandScape = false;

  protected preloadImgLoadPromiseResolver!: (value: any) => void;

  async mounted() {
    this.init();
  }

  @Ref("globalLoading") protected globalLoading!: GlobalLoading;
  @Ref("menu") protected menu!: Menu;
  @Ref("menuButton") protected menuButton!: MenuButton;

  @Ref("kv") protected kv!: KV;
  @Ref("concept") protected concept!: Concept;
  @Ref("features") protected features!: Features;
  @Ref("list") protected list!: List;
  @Ref("footer") protected footer!: Footer;

  @Ref("bgContainer") protected bgContainer!: HTMLElement;

  protected get classObj() {
    return {
      "is-noWebGL": this.isNoWebGL,
      "is-rootLoaded": this.isRootLoaded,
      [`is-${mainStore.contentsState}`]: true,
      // 'is-entered': mainStore.isEntered,
      // 'is-enterTransitionEnded': mainStore.isEnterTransitionEnded,
    };
  }

  protected get isEntered() {
    return mainStore.isEntered;
  }

  protected get isNoWebGL() {
    return mainStore.isNoWebGL;
  }

  protected async init() {
    this.bodyEl = document.body as HTMLBodyElement;
    this.htmlEl = this.bodyEl.parentElement as HTMLHtmlElement;

    this.mqL = window.matchMedia("(min-width: 800px)");
    this.mqL2 = window.matchMedia("(min-width: 1600px)");
    this.mqLL = window.matchMedia("(min-width: 1920px)");
    this.mqM = window.matchMedia("(min-width:800px) and (max-width: 1024px)");
    this.mqM2 = window.matchMedia("(min-width:1025px) and (max-width: 1200px)");
    this.mqSS = window.matchMedia("(max-width: 413px)");

    mainStore.setScrollLeft(window.scrollX || window.pageXOffset);
    mainStore.setScrollTop(window.scrollY || window.pageYOffset);
    mainStore.setWindowWidth(window.innerWidth);
    mainStore.setWindowHeight(window.innerHeight);

    this.scrollToTweenObj = { value: window.scrollY || window.pageYOffset };

    this.initMediaQuery();
    this.initScroll();
    this.initResize();

    let isWebpSupported = false;

    // head fontとwebpのぶん
    this.totalLoadCount += 2;

    const promises: Promise<any>[] = [
      this.$head.webFontLoaderPromise.then(() => this.onLoadCountUp()),
      this.$head.webpLoadPromise.then((_isWebpSupported: boolean) => {
        isWebpSupported = _isWebpSupported;
        this.onLoadCountUp();
      }),
      this.loadData(),
    ];

    // check webgl
    if (this.$head.uaChecks.isIE11 || !WebGLBase.isWebGLAvailable) {
      // if(true) {
      // without webGL
      await Promise.all(promises);
      mainStore.setIsNoWebGL(true);
    } else {
      // webGL is Available
      this.totalLoadCount += KV_TEXTURES.length;
      this.totalLoadCount += BG_TEXTURES.length;

      await Promise.all(promises);

      mainStore.setIsNoWebGL(false);
      this.bgGL = new BgGL(
        this.bgContainer,
        this.bgContainer.querySelector("canvas")
      );
      await this.bgGL.init();

      // scroll
      if (this.$head.uaChecks.isFirefox) this.scrollDeltaRatio = 10;

      // webp
      this.convertToWebP(KV_TEXTURES, isWebpSupported);
      this.convertToWebP(BG_TEXTURES, isWebpSupported);

      await Promise.all([
        this.bgGL.initKVTextuers(KV_TEXTURES, () => this.onLoadCountUp()),
        this.bgGL.initCommonTextures(BG_TEXTURES, () => this.onLoadCountUp()),
      ]);

      // datGUI
      // this.bgGL.initDatGUI(new GUI())
    }

    this.kv.setTextures(KV_TEXTURES);

    this.contents = {
      [ContentsState.KV]: this.kv,
      [ContentsState.CONCEPT]: this.concept,
      [ContentsState.FEATURES]: this.features,
      [ContentsState.LIST]: this.list,
      [ContentsState.FOOTER]: this.footer,
    };

    await Promise.all([
      this.kv.init(),
      this.concept.init(),
      this.features.init(),
      this.list.init(),
      this.footer.init(),
    ]);

    if (!mainStore.isNoWebGL) {
      const kvMesh = this.bgGL.getKVMesh();
      const commonMesh = this.bgGL.getCommonMesh();

      this.kv.setGLKVMesh(kvMesh);
      this.concept.setGLKVMesh(kvMesh);
      this.features.setGLKVMesh(kvMesh);
      this.list.setGLKVMesh(kvMesh);
      this.footer.setGLKVMesh(kvMesh);

      this.kv.setGLCommonMesh(commonMesh);
      this.concept.setGLCommonMesh(commonMesh);
      this.features.setGLCommonMesh(commonMesh);
      this.list.setGLCommonMesh(commonMesh);
      this.footer.setGLCommonMesh(commonMesh);

      this.initKeyBoardAction();
    } else {
      // object-fit pollyfill
      objectFitImages();
      objectFitVideos();
    }

    this.handleResize();

    this.onLoadPage();
  }

  protected initKeyBoardAction() {
    window.addEventListener("keyup", (e) => {
      if (e.key === "ArrowDown") {
        // next
        this.onScroll(1);
      } else if (e.key === "ArrowUp") {
        // prev
        this.onScroll(-1);
      }
    });
  }

  protected get isKV() {
    return mainStore.contentsState === ContentsState.KV;
  }

  protected get isUIAvailable() {
    return mainStore.isUIAvailable;
  }

  protected convertToWebP(
    texturesData: TextureData[],
    isWebpSupported: boolean
  ) {
    texturesData.map((textureData) => {
      if (isWebpSupported) {
        textureData.S.src = textureData.S.src.replace(".jpg", ".webp");
        textureData.L.src = textureData.L.src.replace(".jpg", ".webp");
      }
      return textureData;
    });
  }

  // scroll to
  public async scrollTo(
    posTo: number,
    duration = 1,
    delay = 0,
    ease = Expo.easeInOut,
    isCancelable = true,
    onStart?: () => void,
    onEnd?: () => void
  ): Promise<void> {
    const scrollHeight =
      (this.bodyEl.scrollHeight || this.htmlEl.scrollHeight) -
      window.innerHeight;
    posTo = Math.min(posTo, scrollHeight);

    this.killScrollTween();

    return new Promise((resolve) => {
      const posFrom = window.scrollY || window.pageYOffset;

      if (posTo === posFrom) return resolve();

      const scrollToTweenObj = { value: posFrom };
      if (isCancelable) {
        this.scrollToTweenObj = scrollToTweenObj;
      }

      gsap.to(scrollToTweenObj, duration, {
        value: posTo,
        overwrite: true,
        ease,
        delay,
        onStart: () => {
          if (onStart !== undefined) onStart();
        },
        onUpdate: () => {
          window.scrollTo(0, scrollToTweenObj.value);
        },
        onComplete: () => {
          if (onEnd !== undefined) onEnd();
          return resolve();
        },
      });
    });
  }

  protected initScroll(): void {
    this.handleScroll = this.handleScroll.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    window.addEventListener("scroll", this.handleScroll, { passive: true });
    window.addEventListener("wheel", this.handleWheel, { passive: false });
    window.addEventListener("touchstart", this.handleTouchStart, {
      passive: false,
    });
    window.addEventListener("touchmove", this.handleTouchMove, {
      passive: false,
    });
    window.addEventListener("touchend", this.handleTouchEnd, {
      passive: false,
    });
  }

  protected handleScroll(e?: Event): void {
    mainStore.setScrollLeft(window.scrollX || window.pageXOffset);
    mainStore.setScrollTop(window.scrollY || window.pageYOffset);
  }

  // init resize action
  protected initResize() {
    if (this.$head.device === "mobile") {
      this.resizeTimer = null;
      this.mobileResizeHanndler = this.mobileResizeHanndler.bind(this);
      window.addEventListener("orientationchange", this.mobileResizeHanndler, {
        passive: true,
      });
      window.addEventListener("resize", this.mobileResizeHanndler, {
        passive: true,
      });
    } else {
      this.handleResize = this.handleResize.bind(this);
      window.addEventListener("resize", this.handleResize, { passive: true });
    }
  }

  protected mobileResizeHanndler(e?: Event): void {
    if (this.resizeTimer != null) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }
    this.resizeTimer = window.setTimeout(
      () => {
        this.handleResize();
      },
      this.$head.isAndroid ? 300 : 100
    );
  }

  protected handleResize(e?: Event): void {
    mainStore.setWindowWidth(window.innerWidth);
    mainStore.setWindowHeight(window.innerHeight);
    this.mediaQueryHandler();
    this.handleScroll();
    this.menu?.onResize();

    this.kv?.onResize();
    this.concept?.onResize();
    this.features?.onResize();
    this.list?.onResize();
    this.footer?.onResize();

    this.bgGL?.onResize();

    this.isLandScape = window.orientation === 90 || window.orientation === -90;
  }

  protected initMediaQuery(): void {
    this.mediaQueryHandler(null, true);
  }

  // change break point
  protected mediaQueryHandler(e: Event | null = null, checkOnly = false): void {
    if (this.mqLL.matches) {
      if (mainStore.layoutMode === LayoutMode.LL) return;
      mainStore.setLayoutMode(LayoutMode.LL);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log("layout mode " + LayoutMode.LL);
    } else if (this.mqL2.matches) {
      if (mainStore.layoutMode === LayoutMode.L2) return;
      mainStore.setLayoutMode(LayoutMode.L2);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log("layout mode " + LayoutMode.L2);
    } else if (this.mqM2.matches) {
      if (mainStore.layoutMode === LayoutMode.M2) return;
      mainStore.setLayoutMode(LayoutMode.M2);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log("layout mode " + LayoutMode.M2);
    } else if (this.mqM.matches) {
      if (mainStore.layoutMode === LayoutMode.M) return;
      mainStore.setLayoutMode(LayoutMode.M);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log("layout mode " + LayoutMode.M);
    } else if (this.mqL.matches) {
      if (mainStore.layoutMode === LayoutMode.L) return;
      mainStore.setLayoutMode(LayoutMode.L);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.L);
      console.log("layout mode " + LayoutMode.L);
    } else if (this.mqSS.matches) {
      if (mainStore.layoutMode === LayoutMode.SS) return;
      mainStore.setLayoutMode(LayoutMode.SS);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.S);
      console.log("layout mode " + LayoutMode.SS);
    } else {
      if (mainStore.layoutMode === LayoutMode.S) return;
      mainStore.setLayoutMode(LayoutMode.S);
      mainStore.setSimpleLayoutMode(SimpleLayoutMode.S);
      console.log("layout mode " + LayoutMode.S);
    }
  }

  public goTo(contentsState: ContentsStateProp) {
    if (this.bgGL.getIsAnimating()) return;
    const currentContents = this.contents[mainStore.contentsState] as Section;
    const nextContents = this.contents[contentsState] as Section;
    const beforeContentsState = mainStore.contentsState;
    if (contentsState === beforeContentsState) return;
    if (contentsState === ContentsState.CONCEPT) {
      this.bgGL.playVideo();
    }
    nextContents.reset();
    currentContents.leave(beforeContentsState, contentsState, async () => {
      mainStore.setContentsState(contentsState);
      nextContents.enter(beforeContentsState, contentsState, () => {
        nextContents.focus();
        currentContents.reset();
        if (contentsState !== ContentsState.CONCEPT) {
          this.bgGL.pauseVideo();
        }
      });
    });
  }

  protected onScroll(dir: number) {
    if (!this.bgGL.getIsAnimating()) {
      if (dir > 0) {
        // 進む方向
        if (mainStore.contentsState === ContentsState.KV) {
          // CONCEPTへ遷移
          this.goToConcept();
        } else if (mainStore.contentsState === ContentsState.CONCEPT) {
          // FEATURESへ遷移
          this.goToFeatures();
        } else if (
          mainStore.contentsState === ContentsState.FEATURES &&
          this.features.getCanGoNext()
        ) {
          // LISTへ遷移
          this.goToList();
        } else if (mainStore.contentsState === ContentsState.LIST) {
          // FOOTERへ遷移
          this.goToFooter();
        }
      } else if (dir < 0) {
        // 戻る
        if (mainStore.contentsState === ContentsState.FOOTER) {
          // LISTへ遷移
          this.goToList();
        } else if (mainStore.contentsState === ContentsState.LIST) {
          // FEATURESへ遷移
          this.goToFeatures();
        } else if (
          mainStore.contentsState === ContentsState.FEATURES &&
          this.features.getCanGoPrev()
        ) {
          // CONCEPTへ遷移
          this.goToConcept();
        } else if (mainStore.contentsState === ContentsState.CONCEPT) {
          // KVへ遷移
          this.goToKV();
        }
      }
    }
  }

  protected handleWheel(e: WheelEvent): void {
    if (this.isNoWebGL) {
      if (!mainStore.isRootLoaded || !mainStore.isEntered) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    } else {
      const d = e.deltaY * this.scrollDeltaRatio;
      const dir = d > 20 ? 1 : d < -20 ? -1 : 0;
      this.onScroll(dir);
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }
  }

  protected handleTouchStart(e: TouchEvent): void {
    const touches = e.touches;

    if (this.isNoWebGL) {
      if (
        touches.length !== 1 &&
        (!mainStore.isRootLoaded || !mainStore.isEntered)
      ) {
        // e.preventDefault();
        // e.stopImmediatePropagation();
      }
    } else {
      this.isSwiping = false;
      const touch = touches[0];
      this.touchPoint.x = touch.clientX;
      this.touchPoint.y = touch.clientY;
      this.touchStartPoint.x = touch.clientX;
      this.touchStartPoint.y = touch.clientY;
      // e.preventDefault();
      // e.stopImmediatePropagation();
    }
  }
  protected handleTouchMove(e: TouchEvent): void {
    const touches = e.touches;

    if (this.isNoWebGL) {
      if (
        touches.length !== 1 &&
        (!mainStore.isRootLoaded || !mainStore.isEntered)
      ) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    } else {
      this.isSwiping = true;
      const touch = touches[0];
      this.touchPoint.x = touch.clientX;
      this.touchPoint.y = touch.clientY;
      if (touches.length === 1) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    }
  }
  protected handleTouchEnd(e: TouchEvent): void {
    const touches = e.touches;

    if (this.isNoWebGL) {
      if (
        touches.length !== 1 &&
        (!mainStore.isRootLoaded || !mainStore.isEntered)
      ) {
        // e.preventDefault();
        // e.stopImmediatePropagation();
      }
    } else {
      this.isSwiping = false;
      const d = -(this.touchPoint.y - this.touchStartPoint.y);
      const dir = d > 50 ? 1 : d < -50 ? -1 : 0;
      this.onScroll(dir);
      // e.preventDefault();
      // e.stopImmediatePropagation();
    }
  }

  protected killScrollTween(): void {
    gsap.killTweensOf(this.scrollToTweenObj);
  }

  protected get isRootLoaded() {
    return mainStore.isRootLoaded;
  }

  protected onLoadPage() {
    if (mainStore.isRootLoaded) {
      // transition
      mainStore.setIsInTransition(false);
      mainStore.setIsInChildTransition(false);
    } else {
      // 初回ロード
      // mainStore.setIsLoaded(true);
      this.bgGL?.start();
      this.kv.playOpening();
    }
    this.mediaQueryHandler();
    this.handleResize();
  }

  protected async loadData() {
    return Promise.all([
      this.loadFeaturesData(),
      this.loadKVPopUpData(),
      this.loadListData(),
    ]);
  }

  protected async loadListData() {
    this.totalLoadCount++;
    const response: AxiosResponse<ListItemData[]> | void = await axios
      .get(LIST_DATA_JSON, {})
      .catch((e: AxiosError) => {
        console.error(e);
      });

    if (response) {
      this.onLoadCountUp();
      this.listData = response.data;
      this.list.setListData(this.listData);
      this.footer.setListData(this.listData);
    }
  }

  protected async loadFeaturesData() {
    this.totalLoadCount++;
    const response: AxiosResponse<FeaturesData[]> | void = await axios
      .get(FEATURES_DATA_API, {})
      .catch((e: AxiosError) => {
        console.error(e);
      });

    if (response) {
      this.onLoadCountUp();
      this.featuresData = response.data;
      console.log(this.featuresData);
    }
  }

  protected async loadKVPopUpData() {
    this.totalLoadCount++;
    const response: AxiosResponse<KVPopUpData[]> | void = await axios
      .get(KV_POPUP_DATA_API, {})
      .catch((e: AxiosError) => {
        console.error(e);
      });

    if (response) {
      this.onLoadCountUp();
      this.kvPopUpData = response.data[0];
      console.log(this.kvPopUpData);
    }
  }

  protected onLoadCountUp() {
    this.loadedCount++;
    console.log(`loaded: ${this.loadedCount} / total: ${this.totalLoadCount}`);
  }

  protected goToKV() {
    if (this.isNoWebGL) {
      this.scrollTo(0, 0.6, 0, Cubic.easeOut);
    } else {
      this.goTo(ContentsState.KV);
    }
    mainStore.setIsMenuOpened(false);
  }

  protected goToConcept() {
    if (this.isNoWebGL) {
      this.scrollTo(
        (this.concept.$el as HTMLElement).offsetTop,
        0.6,
        0,
        Cubic.easeOut
      );
    } else {
      this.goTo(ContentsState.CONCEPT);
    }
    mainStore.setIsMenuOpened(false);
  }

  protected goToFeatures() {
    if (this.isNoWebGL) {
      this.scrollTo(
        (this.features.$el as HTMLElement).offsetTop,
        0.6,
        0,
        Cubic.easeOut
      );
    } else {
      this.goTo(ContentsState.FEATURES);
    }
    mainStore.setIsMenuOpened(false);
  }

  protected goToList() {
    if (this.isNoWebGL) {
      this.scrollTo(
        (this.list.$el as HTMLElement).offsetTop,
        0.6,
        0,
        Cubic.easeOut
      );
    } else {
      this.goTo(ContentsState.LIST);
    }
    mainStore.setIsMenuOpened(false);
  }

  protected goToFooter() {
    if (this.isNoWebGL) {
      this.scrollTo(
        (this.footer.$el as HTMLElement).offsetTop,
        0.6,
        0,
        Cubic.easeOut
      );
    } else {
      this.goTo(ContentsState.FOOTER);
    }
    mainStore.setIsMenuOpened(false);
  }
}


export const SP_DESIGN_WIDTH_BASIS = 828;
export const PC_DESIGN_WIDTH_BASIS = 1600;

export const LayoutMode = {
  SS: 'SS',
  S: 'S',
  M: 'M',
  M2: 'M2',
  L: 'L',
  L2: 'L2',
  LL: 'LL',
} as const
export type LayoutModeProp = typeof LayoutMode[keyof typeof LayoutMode];

export const SimpleLayoutMode = {
  S: 'S',
  L: 'L',
} as const
export type SimpleLayoutModeProp = typeof SimpleLayoutMode[keyof typeof SimpleLayoutMode];

export const ContentsState = {
  KV: 'kv',
  CONCEPT: 'concept',
  FEATURES: 'features',
  LIST: 'list',
  FOOTER: 'footer',
} as const
export type ContentsStateProp = typeof ContentsState[keyof typeof ContentsState];

export const ContentsIndex = {
  [ContentsState.KV]: 0,
  [ContentsState.CONCEPT]: 1,
  [ContentsState.FEATURES]: 2,
  [ContentsState.LIST]: 3,
  [ContentsState.FOOTER]: 4,
} as const;
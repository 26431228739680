
import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'

import {
  LayoutModeProp,
  SimpleLayoutModeProp,
  ContentsState,
  ContentsStateProp
} from '@/constants';
import store from './';

export type MainState = {
  isRootLoaded: boolean;
  isScrolled: boolean;
  isInTransition: boolean;
  isInChildTransition: boolean;
  isTransitOnce: boolean;
  isMenuOpened: boolean;
  isSoundOn: boolean;
  isEntered: boolean;
  isEnterTransitionEnded: boolean;
  isNoWebGL: boolean;
  isUIAvailable: boolean;
  isOpeningFinished: boolean;

  scrollTop: number;
  scrollLeft: number;
  windowWidth: number;
  windowHeight: number;

  layoutMode: LayoutModeProp;
  simpleLayoutMode: SimpleLayoutModeProp;

  currentPageId: string;
  nextPageId: string;

  isFeatureContentsOpened: boolean;
  contentsState: ContentsStateProp;
}

@Module({ dynamic: true, store, name: 'main' })
export class Main extends VuexModule implements MainState {
  public isRootLoaded = false;
  public isScrolled = false;
  public isInTransition = false;
  public isInChildTransition = false;
  public isTransitOnce = false;
  public isMenuOpened = false;
  public isSoundOn = false;
  public isEntered = false;
  public isEnterTransitionEnded = false;
  public isNoWebGL = true;
  public isUIAvailable = false;
  public isOpeningFinished = false;

  public scrollTop = 0;
  public scrollLeft = 0;
  public windowWidth = 0;
  public windowHeight = 0;

  public layoutMode: LayoutModeProp = 'S';
  public simpleLayoutMode: SimpleLayoutModeProp = 'S';

  public currentPageId = 'index';
  public nextPageId = 'index';

  public isFeatureContentsOpened = false;
  public contentsState = ContentsState.KV as ContentsStateProp;

  @Mutation
  public enter(): void {
    this.isEntered = true;
  }

  @Mutation
  public entered(): void {
    this.isEnterTransitionEnded = true;
  }

  @Mutation
  public setIsLoaded(isRootLoaded: boolean): void {
    this.isRootLoaded = isRootLoaded;
  }

  @Mutation
  public setIsScrolled(isScrolled: boolean): void {
    this.isScrolled = isScrolled;
  }

  @Mutation
  public setIsInTransition(isInTransition: boolean): void {
    this.isInTransition = isInTransition;
  }

  @Mutation
  public setIsInChildTransition(isInChildTransition: boolean): void {
    this.isInChildTransition = isInChildTransition;
  }

  @Mutation
  public setIsTransitOnce(isTransitOnce: boolean): void {
    this.isTransitOnce = isTransitOnce;
  }
  
  @Mutation
  public setIsNoWebGL(isNoWebGL: boolean): void {
    this.isNoWebGL = isNoWebGL;
  }

  @Mutation
  public toggleMenu(isMenuOpened: boolean): void {
    this.isMenuOpened = isMenuOpened;
  }

  @Mutation
  public openMenu(): void {
    this.isMenuOpened = true;
  }

  @Mutation
  public closeMenu(): void {
    this.isMenuOpened = false;
  }


  @Mutation
  public setScrollTop(scrollTop: number): void {
    this.scrollTop = scrollTop;
  }

  @Mutation
  public setScrollLeft(scrollLeft: number): void {
    this.scrollLeft = scrollLeft;
  }

  @Mutation
  public setWindowWidth(windowWidth: number): void {
    this.windowWidth = windowWidth;
  }

  @Mutation
  public setWindowHeight(windowHeight: number): void {
    this.windowHeight = windowHeight;
  }

  @Mutation
  public setLayoutMode(layoutMode: LayoutModeProp): void {
    this.layoutMode = layoutMode;
  }

  @Mutation
  public setSimpleLayoutMode(simpleLayoutMode: SimpleLayoutModeProp): void {
    this.simpleLayoutMode = simpleLayoutMode;
  }


  @Mutation
  public setCurrentPageId(currentPageId: string): void {
    this.currentPageId = currentPageId;
  }

  @Mutation
  public setNextPageId(nextPageId: string): void {
    this.nextPageId = nextPageId;
  }

  @Mutation
  public setIsMenuOpened(isMenuOpened: boolean): void {
    this.isMenuOpened = isMenuOpened;
  }

  @Mutation
  public setIsSoundOn(isSoundOn: boolean): void {
    this.isSoundOn = isSoundOn;
  }
  
  @Mutation
  public setIsFeatureContentsOpened(isFeatureContentsOpened: boolean): void {
    this.isFeatureContentsOpened = isFeatureContentsOpened;
  }
  
  @Mutation
  public setContentsState(contentsState: ContentsStateProp): void {
    this.contentsState = contentsState;
  }
  
  @Mutation
  public setIsUIAvailable(isUIAvailable: boolean): void {
    this.isUIAvailable = isUIAvailable;
  }

  @Mutation
  public setIsOpeningFinished(isOpeningFinished: boolean): void {
    this.isOpeningFinished = isOpeningFinished;
  }
}

export const mainStore = getModule(Main)
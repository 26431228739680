
import { Color               } from 'three/src/math/Color'                  ;
import { Vector2             } from 'three/src/math/Vector2'                ;
import { Mesh                } from 'three/src/objects/Mesh'                ;
import { PlaneBufferGeometry } from 'three/src/geometries/PlaneGeometry'    ;
import { RawShaderMaterial   } from 'three/src/materials/RawShaderMaterial' ;
import { Texture             } from 'three/src/textures/Texture'            ;
import { VideoTexture        } from 'three/src/textures/VideoTexture'       ;
import { Material            } from 'three/src/materials/Material'          ;

import { GUI } from 'dat.gui';

import CustomTexture from './CustomTexture';

import { SimpleLayoutModeProp } from '@/constants';
import { mainStore } from '@/store/main';
import { TextureData } from '@/@types/common';

export default class WebGLMesh extends Mesh {
  protected simpleLayoutMode: SimpleLayoutModeProp | '' = '';
  protected texturesData: TextureData[] = [];
  protected customTextures: CustomTexture[] = [];
  protected width = 0;
  protected height = 0;
  protected maxTextureSize = 2048;
  protected pixelRatio = 1;
  protected isAnimating = false;

  constructor(geometry: PlaneBufferGeometry) {
    super(geometry);
    this.matrixAutoUpdate = false;
    this.initMaterial();
  }

  protected initMaterial() {
    (this.material as Material).dispose();
  }

  public initDatGUI(datGUI: GUI) {
  }

  public getIsAnimating() {
    return this.isAnimating;
  }

  public async loadTextures(loadItemCallback: () => void = ()=> {}, maxTextureSize = 2048) {
    this.simpleLayoutMode = mainStore.simpleLayoutMode;
    this.maxTextureSize = maxTextureSize;

    const promises: Promise<any>[] = [];
    for (const textureData of this.texturesData) {
      const customTexture = new CustomTexture(textureData, this.width, this.height, this.pixelRatio, this.maxTextureSize)
      promises.push(customTexture.load().then(()=> {
        loadItemCallback()
      }));
      this.customTextures.push(customTexture);
    }
    await Promise.all(promises)
    this.updateMaterial();
  }

  protected async updateTextures() {
    const promises: Promise<any>[] = [];
    for (const customTexture of this.customTextures) {
      promises.push(customTexture.onResize(this.width, this.height, this.pixelRatio));
    }
    await Promise.all(promises)
    this.updateMaterial();
  }
  
  protected updateMaterial() {}

  public setTexturesData(texturesData: TextureData[]) {
    this.texturesData = texturesData;
  }

  public async onResize(width = 1, height = 1, pixelRatio = 1) {
    this.width = width;
    this.height = height;
    this.pixelRatio = pixelRatio;
    if(!this.material) return;
    const material = this.material as RawShaderMaterial
    material.uniforms.resolution.value.x = width * pixelRatio;
    material.uniforms.resolution.value.y = height * pixelRatio;
    this.updateMatrix();
    await this.updateTextures();
  }

  public setUV(uvOffset: Vector2, uvSize: Vector2) {
    const material = this.material as RawShaderMaterial
    material.uniforms.uvOffset.value = uvOffset
    material.uniforms.uvSize.value = uvSize
  }

  protected setTextureParams(uniformTextureIndex: number, textureIndex: number) {
    if(textureIndex === -1) return
    const customTexture = this.customTextures[textureIndex];
    const material = this.material as RawShaderMaterial
    material.uniforms[`uvOffset${uniformTextureIndex}`].value = customTexture.getUVOffset()
    material.uniforms[`uvSize${uniformTextureIndex}`].value = customTexture.getUVSize()
  }

  public enable() {
    (this.material as RawShaderMaterial).uniforms.isEnabled.value = 1;
  }
  public disable() {
    (this.material as RawShaderMaterial).uniforms.isEnabled.value = 0;
  }

}
